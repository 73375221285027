import { Space } from 'antd';
import {tz} from "moment-timezone"; //DON'T DELETE THIS, MOBI IS CRASHING WITHOUT IT!!!
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useDispatch, useSelector } from 'react-redux';
import { STAFF_SELECT, TASK_TYPES_SELECT, USERS_SELECT } from '../../../services/ConstHelper';
import {
    dbDateTimeFormat,
    dbDateFormat,
    dbTimeFormat,
    targetAudienceTasks,
    targetAudienceTasksEdit,
    isMobile,
    getBoxDateFormat, getBoxTimeFormat, targetAudienceBulkTasks
} from '../../../services/Helpers';
import { fetchApi } from '../../../services/HTTP';
import { t } from '../../../services/i18n';
import { GlobalStyleAttributes } from '../../../styles/GlobalStyles';
import AdvanceSelect from '../../common/AdvanceSelect';
import CustomCheckbox from '../../common/CustomCheckbox';
import FormInput from '../../common/FormInput';
import SharedTimepicker from '../../common/SharedTimepicker';
import usePermissions from '../../../services/Permissions';
import { setError } from '../../../redux/stepperSlice';
import ConfirmModal from '../../common/ConfirmModal';
import AppText from '../../common/AppText';
import InfoTooltip from '../../common/InfoTooltip';
import CustomButtonV2 from '../../common/CustomButtonV2';
import { Colors } from '../../../styles/Colors';
import { ActivityIndicator } from 'react-native';
import { Roles } from '../../../Configs/DatabaseConsts';
import { Spinner } from 'native-base';
import { sendMessageToAngular } from '../../../services/IframeIntegrationServices';
import {getTaskType} from "../../../../web/src/components/Tasks/TasksHelper";

const TaskDetails = props => {
    const { values, handleChange, setFieldValue, target, errors, touched, handleSubmit } = props;
    const { has } = usePermissions()
    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [staffMembers, setStaffMembers] = useState(null);
    const [confirmSave, setConfirmSave] = useState(null);
    const [taskDoneLoader, setTaskDoneLoader] = useState(false);
    const [doneDetails, setDoneDetails] = useState(null)
    const box = useSelector(state => state.box?.box)
    const stepperError = useSelector(state => state.stepper?.error)
    const stepperSuccess = useSelector(state => state.stepper?.stepperSuccess)
    const loggedUser = useSelector(state => state.user.user)
    const isReports = useMemo(() => target?.targeted === 'reports', []);
    const selectedUsers = useMemo(() => target?.selectedUsers, []);
    const targetOptions = useMemo(() => Object.values(target?.user_fk ? targetAudienceTasksEdit : (has('createBulkTasks') ? { ...targetAudienceTasks, ...targetAudienceBulkTasks} : targetAudienceTasks)).filter(optionValue => has('personalTaskOption') ? true : optionValue !== 'personal').map(ta => ({ value: ta, label: t('task-' + ta.replaceAll('_', '-')) })), [])
    const locationOptions = useMemo(() => box?.locations_box?.map(location => ({ value: location.id, label: location.location })), [box]);
    const taskTypeOptions = useMemo(() => box?.task_types.filter(t => t.active).map(t => ({ ...t, value: t.id, label: getTaskType(t.type) })), [box]);
    const defualtMomentReminder = useMemo(() => moment(target?.reminder_time).format(`${dbDateFormat} HH:mm:00`), [])
    const dispatch = useDispatch();
    const boxDateFormat = getBoxDateFormat();
    const boxTimeFormat = getBoxTimeFormat();

    useEffect(() => {
        fetchStaffMembers()
        if (target?.user) {
            setUser({label: target?.user.full_name, value: target?.user_fk, type: 'lead', ...target.user})
        }
        if (taskTypeOptions.length === 1) {
            onTaskTypeChange(taskTypeOptions[0])
        }
    }, []);

    useEffect(() => {
        if (values?.targeted && values?.targeted !== targetAudienceTasks.SPECIFIC_USER && values?.targeted !== targetAudienceTasks.GENERAL && values?.targeted !== targetAudienceTasks.PERSONAL) {
            setFieldValue('assigned_to', null)
        }
    }, [values?.targeted])

    useEffect(() => {
        if (stepperSuccess) {
            setUser(null)
        }
    }, [stepperSuccess])

    useEffect(() => {
        setFieldValue('confirmed', false)
        if (stepperError?.message === 'confirm-edit-task') {
            showConfirmSave()
        }
    }, [stepperError])

    const showConfirmSave = async () => {
        let res = null;
        if (!target?.id) {
            if (!isReports) {
                res = await fetchApi('tasks/getTargetedUsersCount', 'POST', values, false, true);
            } else {
                res = Object.keys(selectedUsers).length;
            }
        }
        setConfirmSave({
            header: target?.id ? t('confirm-edit-task-header') : t('confirm-add-task-header', {}),
            subheader: target?.id ? t('confirm-edit-task-subheader') : t('confirm-add-task-subheader-number', {number: res, type: t('task-' + values.targeted?.replaceAll('_', '-'))}),
            confirmText: t('confirm'),
            cancelText: t('cancel'),
            onConfirm: () => setFieldValue('confirmed', true) | handleSubmit() | setConfirmSave(null),
            onCancel: () => setConfirmSave(null),
        })
        dispatch(setError(null))
    }

    const selectUser = (user) => {
        setUser(user)
        setFieldValue('user_fk', user?.user_fk || null)
        setFieldValue('locations_box_fk', user?.locations_box_fk || null)
        if (staffMembers && user?.users_boxes_owner_id) {
            const leadOwner = staffMembers.find(sm => sm.id === user?.users_boxes_owner_id);
            if (leadOwner) {
                setFieldValue('assigned_to', leadOwner.user_fk)
            }
        }
        setFieldValue('locations_box_fk', user?.locations_box_fk || null)
    }

    const onDateChange = (value) => {
        if (value && moment(value).isValid()) {
            const date = moment(value).format(dbDateFormat);
            const time = moment(values.reminder_time || defualtMomentReminder).format(dbTimeFormat);
            const dateTime = `${date} ${time}`
            if (dateTime !== moment(values.reminder_time || defualtMomentReminder).format(dbDateTimeFormat)) {
                setFieldValue('reminder_time', dateTime);
            }
        }
    }

    const onTimeChange = (value) => {
        if (value.m) {
            const time = value.m.format(dbTimeFormat)
            const date = moment(values.reminder_time || defualtMomentReminder).format(dbDateFormat);
            setFieldValue('reminder_time', `${date} ${time}`)
        }
        else if (value && moment(value).isValid()) {
            const time = moment(value).format(dbTimeFormat);
            const date = moment(values.reminder_time).format(dbDateFormat);
            const dateTime = `${date} ${time}`
            if (dateTime !== moment(values.reminder_time || defualtMomentReminder).format(dbDateTimeFormat)) {
                setFieldValue('reminder_time', dateTime);
            }
        }
    }

    const fetchStaffMembers = async () => {
        let staffMembersRes = await fetchApi('getStaffMembersOfBox', 'get', {exclude: [Object.keys(Roles).findIndex(x => x === Roles.COACH) + 1]}, false, true);
        staffMembersRes.forEach(staffMember => {
            staffMember.value = staffMember.user_fk
            staffMember.label = `${staffMember.first_name} ${staffMember.last_name || ''}`
            return staffMember
        })
        setStaffMembers(staffMembersRes);
        setFieldValue('reminder_time', defualtMomentReminder)
        setIsLoading(false);
        if (target?.id) return;
        if (staffMembersRes.length === 1) {
            setFieldValue('assigned_to', staffMembersRes[0].user_fk)
        } else {
            if (target?.user?.users_boxes_owner_id) {
                const leadOwner = staffMembersRes.find(sm => sm.id === target?.user?.users_boxes_owner_id);
                if (leadOwner) {
                    setFieldValue('assigned_to', leadOwner.user_fk)
                }
            }
        }
    }

    const onTaskTypeChange = (option) => {
        setFieldValue('task_type_fk', option?.value ?? null)
        setFieldValue('check_in', option?.check_in)
        setFieldValue('access_control', option?.access_control)
    }

    const setTaskAsDone = async () => {
        const done = values.done === 0
        setTaskDoneLoader(true)
        setFieldValue('is_done_button_click', true)
        setFieldValue('done', done ? 1 : 0)
        setTaskDoneLoader(false)
    }

    const onTargetChange = (option) => {
        setFieldValue('targeted', option.value ?? null)
        if(option.value !== 'personal') setFieldValue('is_personal', 0)
        else {
            setFieldValue('is_personal', 1)
            setFieldValue('assigned_to', loggedUser.user_fk)
        }
    }

    const getTooltipText = () => {
        return target?.done_by ? t('doneDetailsTooltip', {doneBy: target?.done_by?.full_name, doneTime: moment(target?.done_time).format(boxDateFormat + ' ' + boxTimeFormat)}) : t('noDoneBy', {doneTime: moment(target?.done_time).format(boxDateFormat + ' ' + boxTimeFormat)})
    }

    if (isLoading) {
        return <ActivityIndicator />
    }

    return (<>
        {isReports ?
            <FormInput
                label={t('targetAudience')}
                value={t('targeted-users', {count: Object.keys(selectedUsers).length})}
                width={'100%'}
                disabled
            />
        : <>
            {!(target?.targeted === targetAudienceTasks.SPECIFIC_USER && target?.id || target?.user) && <FormInput label={`${t('targeted-clients-task')}`}>
                <AdvanceSelect dontSort placeholder={t('target-audience-select')} options={targetOptions} initValue={values.targeted} onChange={(option) => onTargetChange(option)} />
            </FormInput>}
            {values.targeted === targetAudienceTasks.SPECIFIC_USER && <FormInput
                label={t('task-specific-user')}
                error={(!values.user_fk && touched.user_fk && errors.user_fk) || null}
                containerProps={{mb: '2rem'}}
                helper={values.user_fk && !target?.user?.profile && <a href={'/user-profile/' + values.user_fk} target={'_blank'}>
                    <AppText fontSize={'14px'} color={Colors.newDesign.mainBlue}>{t('open-user-profile')}</AppText>
                </a>}
                width={'100%'}>
                <AdvanceSelect isDisabled={!!target?.user?.profile} placeholder={t('specific-user-select')} initValue={user} asyncInit allowInactive type={USERS_SELECT} onChange={selectUser} hideFreefitType hideCustomerId isClearable={true} isError={touched.user_fk && errors.user_fk}/>
            </FormInput>}
            {locationOptions.length > 1 && values.targeted !== targetAudienceTasks.SPECIFIC_USER &&
                <FormInput containerProps={{ mb: '2rem' }} label={t('location')} width={'100%'} error={(touched.locations_box_fk && errors.locations_box_fk) ? errors.locations_box_fk : null}>
                    <AdvanceSelect dontSort options={locationOptions} initValue={values.locations_box_fk} onChange={(option) => setFieldValue('locations_box_fk', option.value ?? null)} isError={touched.locations_box_fk && errors.locations_box_fk}/>
                </FormInput>}
        </>}
        <Space direction={isMobile ? 'vertical' : 'horizontal'} className={'antd-custom-space'}>
            <FormInput width={'100%'} label={t('date-create-task')} error={(touched.reminder_time && errors.reminder_time) || null}>
                <SharedTimepicker type={'date'} value={moment(values.reminder_time || defualtMomentReminder, dbDateTimeFormat)} onChange={onDateChange} textStyle={{ fontSize: GlobalStyleAttributes.fonts.standard16 }} />
            </FormInput>
            <FormInput width={'100%'} label={t('time-create-task')}>
                <SharedTimepicker type={'time'} value={moment(values.reminder_time || defualtMomentReminder, dbDateTimeFormat)} onChange={onTimeChange} textStyle={{ fontSize: GlobalStyleAttributes.fonts.standard16 }} />
            </FormInput>
        </Space>
        <Space direction={isMobile ? 'vertical' : 'horizontal'} className={'antd-custom-space'}>
            <FormInput containerProps={{ mb: '1rem' }} label={t('assignee-create-task')} width={'100%'} error={(touched.assigned_to && errors.assigned_to) ? errors.assigned_to : null}>
                {(values.targeted === 'personal' || values.is_personal) ?
                    <CustomButtonV2
                        wrapperStyle={{width: '100%'}}
                        tooltipText={t('assignedToDisable')}
                        override={() => <AdvanceSelect options={staffMembers} type={STAFF_SELECT}
                                                       initValue={values.assigned_to}
                                                       onChange={(option) => setFieldValue('assigned_to', option?.value ?? null)}
                                                       isClearable={true}
                                                       isError={touched.assigned_to && errors.assigned_to}
                                                       isDisabled={values.targeted === 'personal' || values.is_personal}
                                                       customStyle={{width: '100%'}}/>}
                    />
                    :
                    <AdvanceSelect options={staffMembers} type={STAFF_SELECT} initValue={values.assigned_to}
                                   onChange={(option) => setFieldValue('assigned_to', option?.value ?? null)}
                                   isClearable={true} isError={touched.assigned_to && errors.assigned_to}
                                   isDisabled={values.targeted === 'personal' || values.is_personal}
                                   customStyle={{width: '100%'}}/>}
            </FormInput>
            <FormInput containerProps={{ mb: '1rem' }} label={t('taskType')} width={'100%'} error={(!values.task_type_fk && touched.task_type_fk && errors.task_type_fk) || null}>
                <AdvanceSelect options={taskTypeOptions} type={TASK_TYPES_SELECT} initValue={values.task_type_fk} onChange={onTaskTypeChange} isClearable={true} isError={touched.task_type_fk && errors.task_type_fk}/>
            </FormInput>
        </Space>
        <FormInput label={t('description')} placeholder={t('task-description-placeholder')} inputType={'multiline'} rows={4} value={values.description} onChangeCallback={handleChange('description')} />
        {target?.id && <FormInput label={t('status')}>
            {
                taskDoneLoader ? <Spinner color={Colors.newDesign.darkGrey} style={{ width: !isMobile && '5.5rem', height: '32px' }} /> : <CustomButtonV2
                                        onClick={setTaskAsDone}
                                        type={'link'}
                                        className={`task-complete-button-${values.done ? 'done' : 'undone'}`}
                                        style={{ border: values.done ? 'solid #0D7F56 1px' : 'solid #E0E0E0 1px', backgroundColor: values.done ? '#E6FAEA' : Colors.newDesign.white, paddingInline: '0.6rem'}}
                                        wrapperStyle={{ width: 'max-content', height: '2rem' }}
                                        iconName={'fa-check'}
                                        text={t(!values.done ? 'mark-complete' : 'completed')}
                                        iconProps={{ iconHoverColor: values.done ? '#0D7F56' : Colors.newDesign.mainText, iconColor: values.done ? '#0D7F56' : Colors.newDesign.mainText }}
                                        applyHoverOnText={true}
                                        tooltipText={target.done_time && values?.done && getTooltipText()}
                                    />}
        </FormInput>}
        <Space direction={'vertical'}>
            {values?.targeted !== targetAudienceTasks.GENERAL &&
                <>
                    <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t('pop-up-task')}</AppText>
                    <CustomCheckbox isChecked={values.check_in} onChange={val => setFieldValue('check_in', val)} text={
                    <Space>
                        {t('show-task-check-in')}
                        <InfoTooltip title={t('show-task-check-in-tooltip')} />
                    </Space>
                    } />
                </>
            }
            {values?.targeted !== targetAudienceTasks.GENERAL && has('burnChipActions') && <CustomCheckbox isChecked={values.access_control} onChange={val => setFieldValue('access_control', val)} text={
                <Space>
                    {t('show-task-access-control')}
                    <InfoTooltip title={t('show-task-access-control-tooltip')} />
                </Space>
                } />}
        <div style={{height: '2rem'}}/>
        </Space>
        {confirmSave && <ConfirmModal
                notAnError
                isOpen={confirmSave}
                header={confirmSave?.header}
                subheader={confirmSave?.subheader}
                confirmText={confirmSave?.confirmText}
                cancelText={confirmSave?.cancelText}
                onConfirm={confirmSave?.onConfirm}
                onCancel={confirmSave?.onCancel}
                onClose={() => setConfirmSave(null)} />}
    </>);
};

export default TaskDetails;