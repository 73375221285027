import FormInput from "../../common/FormInput";
import SimpleInput from "../../common/SimpleInput";
import CustomCheckbox from "../../common/CustomCheckbox";
import {Box, Button, HStack, Spinner, VStack} from 'native-base';
import usePermissions from "../../../services/Permissions";
import AppText from "../../common/AppText";
import CustomButtonV2 from "../../common/CustomButtonV2";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import {Radio} from "antd";
import {GlobalStyleAttributes} from "../../../styles/GlobalStyles";
import {Colors} from "../../../styles/Colors";
import { t } from '../../../services/i18n';
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {getLocale, openUpgrade360Link} from "../../../services/Helpers";
import {setOpenLockedPopup} from "../../../redux/rootManagerSlice";
import {fetchApi} from "../../../services/HTTP";
import InfoTooltip from "../../common/InfoTooltip";
import AdvanceSelect from "../../common/AdvanceSelect";
import {ITEMS_SELECT, USERS_SELECT} from "../../../services/ConstHelper";

const SendMembershipLink = (props) => {
    const { handleChange, values, touched, errors, target, setFieldValue } = props;
    const {has, hasLock} = usePermissions()
    const dispatch = useDispatch()
    const box = useSelector(state => state.box?.box)
    const isIL = box?.locations_box[0]?.country_code === 'IL';
    const [smsBalance, setSmsBalance] = useState(null);
    const hasPackage = !/.*(free|trial)+.*/g.test(box?.packages?.name?.toLowerCase());
    const hasWhatsapp = useMemo(() => box?.integrations?.find(i => i.name === 'upgrade360'), [box]);
    const charCount = values.message_content?.length || 0;
    const smsUnitCount = isIL ? 133 : 70;

    const tags = [
        { id: 'first_name', label: t('firstName') },
        { id: 'last_name', label: t('lastName') },
        { id: 'membership_link', label: t('link-to-membership') },
    ];

    useEffect(() => {
        console.log('values', values)
    }, [values])

    const onTagClick = (label) => {
        if (`${values.message_content || ''} [${label}]`.length <= 320) {
            setFieldValue('message_content', `${values.message_content || ''}[${label}]`)
        }
        document?.getElementById('text-area-message')?.focus()
    }

    useEffect(() => {
        getSmsBalance()
    }, []);
    const openLockPopup = () => {
        dispatch(setOpenLockedPopup(true))
    }
    const handleWhatsappClick = async (val) => {
        if (!hasWhatsapp) {
            openUpgrade360Link()
        } else {
            setFieldValue('send_whatsapp', val)
        }
    }
    const handleEmailClick = async (val) => {
        if (hasLock('emailActions')) {
            openLockPopup()
        } else {
            setFieldValue('send_email', val)
        }
    }
    const getSmsBalance = async () => {
        const res = await fetchApi('boxes/getSmsBalance', 'POST', null, false, true);
        setSmsBalance(res || 0);
    }

    const onMembershipTypeChange = (data) => {
        setFieldValue('item_id', data.id)
        setFieldValue('item_type', data.type)
    }

    return (
        <>
            {/* user */}
            <FormInput
                label={t('specific-user')}
                width={'100%'}
                containerProps={{ mb: '2rem' }}
                error={(touched.user_fk && errors.user_fk) || null}>
                <SimpleInput value={target.user?.full_name} disabled />
            </FormInput>
            {/* item */}
            <FormInput label={t('chooseItemToSend')} containerProps={{ mb: '1.2rem' }}>
                <VStack space={'9px'}>
                    <HStack>
                        <HStack>
                            <Radio.Group value={values.available_on_app} onChange={(e) => setFieldValue('available_on_app', e.target.value)}>
                                    <HStack space={'1.2rem'}>
                                        <Radio value={true} size="sm">
                                            <AppText style={{marginRight: '0.5rem'}}>{t('availableOnApp')}</AppText>
                                        </Radio>
                                        <Radio value={false} size="sm">
                                            <AppText style={{marginRight: '0.5rem'}}>{t('notAvailableOnApp')}</AppText>
                                        </Radio>
                                    </HStack>
                            </Radio.Group>
                        </HStack>
                        <InfoTooltip title={t('sendMembershipLinkInfo')}/>
                    </HStack>
                    <Box flexGrow={1}>
                        <AdvanceSelect initValue={values.item_id} type={ITEMS_SELECT} onChange={onMembershipTypeChange} placeholder={t('selectItemForPurchase')} showInApp={values.available_on_app ? 1 : 0}/>
                    </Box>
                </VStack>
            </FormInput>
            {/* types */}
            <FormInput label={t('message-type')} containerProps={{ mb: '1.2rem' }} error={(touched.send_sms && errors.send_sms) || null}>
                <CustomCheckbox text={t('send-via-push')} isChecked={values.send_push} onChange={val => setFieldValue('send_push', val)}/>
                <CustomCheckbox text={
                    <HStack space={'0.3rem'} alignItems={'center'} height={'1.8rem'}>{t('send-via-sms') + ' '} {
                        <HStack alignItems={'center'}>
                            ({smsBalance !== null ? <AppText>{`${t('your-text-message-balance')}: ${smsBalance?.toLocaleString(getLocale()) || 0}. `}</AppText> : <Spinner color={Colors.newDesign.darkGrey} />}
                            <CustomButtonV2 onClick={() => window.open('/reports-v5/communication?action=smsPurchasePage', '_blank')}
                                            type={'link'}
                                            style={{ width: 'max-content' }}
                                            text={t('purchase-more')}
                                            textProps={{ color: Colors.newDesign.mainBlue }}
                            />
                            )</HStack>
                    }</HStack>
                } isChecked={values.send_sms} onChange={val => setFieldValue('send_sms', val)} />
                {has('emailActions') && <CustomCheckbox style={hasLock(`emailActions`) ? { opacity: 0.5 } : {}} text={
                    <HStack height={'1.8rem'} alignItems={'center'} space={'0.4rem'}>{t('send-via-email')}{hasLock(`emailActions`) && <FontAwesomeIcon icon={'fa-light fa-lock-keyhole'} />}</HStack>
                } isChecked={values.send_email} onChange={handleEmailClick} />}
                {(isIL || has('viewUpgrade360')) && hasPackage && <CustomCheckbox
                    style={!hasWhatsapp ? { opacity: 0.5 } : {}}
                    text={<HStack height={'1.8rem'} alignItems={'center'} space={'0.4rem'}>
                        {t('send-via-whatsapp')}{!hasWhatsapp && <FontAwesomeIcon icon={'fa-light fa-lock-keyhole'} />}
                    </HStack>}
                    isChecked={values.send_whatsapp}
                    onChange={handleWhatsappClick} />}
            </FormInput>
            {/* subject */}
            {has('emailActions') && !hasLock('emailActions') && <FormInput
                label={t('subject')}
                value={values.subject}
                onChangeCallback={handleChange('subject')}
                error={(touched.subject && errors.subject) || null}
                width={'100%'}
                maxLength={40}
                showCount
                containerProps={{ mb: '2rem' }}
                placeholder={t(`subject-email`)}
            />}
            {/* message content */}
            <FormInput label={t('messageContent')} width={'100%'} containerProps={{ mb: '2rem' }}
                       helper={t('link-message-content-helper')}
                       error={(touched.message_content && errors.message_content) ? errors.message_content : null}
                       topEndElement={<AppText variant={GlobalStyleAttributes.textVariants.heading4} color={Colors.newDesign.darkGrey}>{`${charCount % 320}/${320}${` | ${parseInt(charCount / smsUnitCount) + 1} ${t('messages')}`}`}</AppText>}>
                <Box>
                    <HStack borderColor={Colors.borderColor} flexWrap={'wrap'} borderWidth={1} p={'0.3rem'} space={'0.3rem'} borderRadius={4} top={'1px'} alignItems={'center'}>
                        <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{`${t('tags')}:`}</AppText>
                        {tags.map(tag =>
                            <Button
                                bgColor={Colors.newDesign.menuBar}
                                borderColor={(tag.id === 'membership_link' && touched.message_content && errors.message_content === t('link-is-required')) ? '#D63635' : Colors.newDesign.menuBar}
                                borderWidth={'1px'}
                                onPress={() => onTagClick(tag.label)}
                                key={tag.id}
                                width={'auto'}
                                mb={'0.3rem'}
                                size={'md'} h={8}>
                                <AppText variant={GlobalStyleAttributes.textVariants.heading4}>{tag.label}</AppText>
                            </Button>
                        )}
                    </HStack>
                    <SimpleInput inputType={'multiline'}
                                 rows={6}
                                 id={'text-area-message'}
                                 maxLength={320}
                                 placeholder={t('add-message-here')}
                                 value={values.message_content}
                                 onChangeCallback={(val) => setFieldValue('message_content', val)}
                                 status={touched.message_content && errors.message_content ? 'error' : null}
                    />
                </Box>
            </FormInput>
        </>
    );
}

export default SendMembershipLink