import {HStack, VStack} from "native-base";
import {dbDateFormat, isMobile} from "../../services/Helpers";
import AppText from "./AppText";
import {GlobalStyleAttributes} from "../../styles/GlobalStyles";
import {t} from "../../services/i18n";
import {CUSTOM_FIELD_SELECT, customFieldTypes} from "../../services/ConstHelper";
import AdvanceSelect from "./AdvanceSelect";
import SimpleInput from "./SimpleInput";
import SharedTimepicker from "./SharedTimepicker";
import moment from "moment";
import CustomCheckbox from "./CustomCheckbox";
import {Colors} from "../../styles/Colors";
import React, {useEffect, useState} from "react";

const CustomFieldSwitcherCreateModal = (props) => {
    const { customFields, setFieldValue, touched, errors, wrapperStyle, containerStyle} = props;
    const [localState, setLocalState] = useState([]);

    useEffect(() => {
        setLocalState(customFields);
    },[customFields])

    const updateOrAddCustomField = (field, value, index) => {
        setFieldValue(`customFields[${index}]`, {...field, value: value ?? null});
    }


    return (
        <>
            {localState?.map((field, index) => {
                const isNumberType = field.custom_field_type === customFieldTypes.NUMBER;
                const isTextType = field.custom_field_type === customFieldTypes.TEXT;
                const isPhoneType = field.custom_field_type === customFieldTypes.PHONE;
                const isBooleanType = field.custom_field_type === customFieldTypes.BOOLEAN;
                const isDateType = field.custom_field_type === customFieldTypes.DATE;
                const isSelectType = field.custom_field_type === customFieldTypes.SELECT;
                return  <VStack space={'0.2rem'} width={'100%'} marginBottom={'15px'} style={wrapperStyle}>
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} mediumFont>{t(field.custom_field_name)}{field.props?.isRequired ? '*' : ''}</AppText>
                            {
                                (isSelectType || isBooleanType) &&
                                <AdvanceSelect target={field} initValue={field.value}
                                               type={CUSTOM_FIELD_SELECT}
                                               options={(isSelectType && field.props?.options) || (isBooleanType && [{label: t('yes'), value: 1},{label: t('no'), value: 0}])}
                                               onChange={(val) => updateOrAddCustomField(field, val?.value, index)}
                                               isClearable={!field.props?.isRequired}
                                               className={touched.customFields && errors.customFields && errors.customFields[index] && 'custom-select-error'} />
                            }
                            {
                                (isTextType || isNumberType || isPhoneType) &&
                                <SimpleInput key={field.customFieldValueId}
                                    value={field.value}
                                    initValue={field.value}
                                    type={isPhoneType && 'phone'}
                                    autoComplete={isPhoneType && 'phone'}
                                    inputMode={(isPhoneType && 'tel') || (isNumberType && 'tel')}
                                    max={isTextType && 30}
                                    numericOnly={isNumberType}
                                    onChangeCallback={(value) => updateOrAddCustomField(field, value, index)}
                                    className={`${touched.customFields && errors.customFields && errors.customFields[index] && 'custom-input-error'} ${isNumberType && 'custom-input-number'}`} />
                            }
                            {
                                isDateType &&
                                    <SharedTimepicker type={'date'}
                                                      value={field.value ? moment(field.value, dbDateFormat) : null}
                                                      onChange={(date) => updateOrAddCustomField(field, date, index)}
                                                      textStyle={{ fontSize: GlobalStyleAttributes.fonts.standard16 }}
                                                      inputStyle={{ borderColor: touched.customFields && errors.customFields && errors.customFields[index] && '#d04c4f'}}
                                    />
                            }
                            <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.error}>{touched.customFields && errors.customFields && errors.customFields[index] ? errors.customFields[index].value : null}</AppText>
                      </VStack>
        })}
    </>
    );
};

export default CustomFieldSwitcherCreateModal;
