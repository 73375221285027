import { Checkbox, Col, Flex, Row } from 'antd';
import React from 'react';
import AppText from '../../common/AppText';
import { useMemo } from 'react';
import { boxSettingsProps, USER_FIELDS } from '../../../services/ConstHelper';
import { t } from '../../../services/i18n';
import CustomTaskDoneCheckbox from '../../common/CustomTaskDoneCheckbox';
import { Colors } from '../../../styles/Colors';
import { useSelector } from 'react-redux';

const RequiredFieldsSettings = ({initialValues, values, setFieldValue, ...rest}) => {
    const {fields, flows} = useMemo(() => boxSettingsProps[initialValues?.prop_name] || {}, [initialValues]);
    const box = useSelector(state => state.box.box);
    const isGlobal = box?.locations_box[0]?.country_code !== 'IL';
    const spanLength = 24 / (flows?.length + 1); // 24 = ant design grid system base
    
    const isDisabled = (field, flow) => {
        const globalDisabled = isGlobal ? ['city.member', 'address.member'] : [];
        return [...globalDisabled, 'last_name.member', 'source_fk.member', 'users_boxes_owner_id.member', 'personal_id.lead'].includes(`${field}.${flow}`);
    }

    return <div>
        <Row style={{paddingBlock: '0.5rem', borderBottom: '1px solid #e0e0e0'}}>
            <Col span={spanLength - 2}>
                <AppText boldFont>{t('field-name')}</AppText>
            </Col>
            {flows?.map(flow => (
                <Col span={spanLength} style={{textAlign: 'center'}}>
                    <AppText boldFont>{t(`${flow}-flow`)}</AppText>
                </Col>
            ))}
        </Row>
        {fields?.map(field => (
            <Row style={{paddingBlock: '1rem', borderBottom: '1px solid #e0e0e0'}}>
                <Col span={spanLength - 2}>
                    <AppText>{t(`${field.replaceAll('_', '-')}`)}</AppText>
                </Col>
                {flows?.map(flow => (
                    <Col span={spanLength} style={{display: 'flex', justifyContent: 'center'}}>
                        <CustomTaskDoneCheckbox 
                            onClick={() => {}}
                            checkedTooltip={isDisabled(field, flow) ? t(`disabled-${flow}-${field.replaceAll('_', '-')}-tooltip`) : ''}
                            uncheckedTooltip={isDisabled(field, flow) ? t(`disabled-${flow}-${field.replaceAll('_', '-')}-tooltip`) : ''}
                            colors={{primary: Colors.newDesign.mainBlue, primaryBorder: '#E0E0E0', primaryHover: Colors.newDesign.mainBlueHover}}
                            disabled={isDisabled(field, flow)}
                            checked={values.properties?.[field][`${flow}Required`]}
                            onChange={(e) => {
                                setFieldValue(`properties.${field}.${flow}Required`, e.target.checked)
                            }}
                        />
                    </Col>
                ))}
            </Row>
        ))}

    </div>
}

export default RequiredFieldsSettings;