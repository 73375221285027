import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Select, {components, createFilter} from "react-select"
import sortBy from "lodash-es/sortBy";
import orderBy from "lodash-es/orderBy";
import debounce from 'lodash-es/debounce';
import groupBy from 'lodash-es/groupBy';
import AppText from "./AppText";
import {GlobalStyleAttributes} from "../../styles/GlobalStyles";
import {
    CATEGORY_SELECT,
    COACH_SELECT, ITEMS_SELECT,
    LEAD_SOURCE_SELECT,
    LEAD_STATUS_SELECT,
    LOST_REASONS_SELECT,
    MESSAGE_TEMPLATE_SELECT,
    SPACE_SELECT,
    STAFF_SELECT,
    TASK_TYPES_SELECT,
    USERS_SELECT
} from "../../services/ConstHelper";
import {Circle, Divider, HStack, VStack} from "native-base";
import {Colors} from "../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {isRTL, t} from "../../services/i18n";
import {
    CREATE_EVENT_MODAL,
    CREATE_EVENT_TYPE_MODAL,
    openModal,
    SETTINGS_VALUE_MODAL,
    updateModalRes
} from "../../redux/modalManagerSlice";
import {fetchApi} from "../../services/HTTP";
import AsyncSelect from "react-select/async";
import {isMobile, sendDataEvent} from "../../services/Helpers";
import UserAvatar from "./UserAvatar";
import {flowOptions} from '../Structure/Schedule/Popups/CreateEvent/FlowOptions';
import usePermissions from '../../services/Permissions';
import {openStepperModal} from '../../services/stepperService';
import {LEAD_SOURCE_TYPE, LEAD_STATUS_TYPE, LOST_REASONS_TYPE} from '../../Configs/SettingsValueModalOptions';
import useCheckPermission from "../../Hooks/useCheckPermission";
import {BoxCategoryTypes, Roles, StaffMemberRoles} from "../../Configs/DatabaseConsts";
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {light} from '@fortawesome/fontawesome-svg-core/import.macro';
import {GlobalConsts} from '../../styles/GlobalConsts';
import CustomButtonV2 from "./CustomButtonV2";
import { Flex } from 'antd';

const AdvanceSelect = (props) => {
    const { options, initValue, onChange, type, hideCreateBtn, dontSort, customStyle, allowInactive, categoryTypeId, hideLeadType, hideFreefitType, hideArchived, hideCustomerId, target, placeholder, fontSize, asyncInit, isError, showInApp,...rest } = props;
    const createEventTypeModalRes = useSelector(state => state.modalManager.createEventTypeModal.processRes)
    const createEventModalRes = useSelector(state => state.modalManager.createEventModal.processRes)
    const selectedLocation = useSelector(state => state.userSettings.scheduleLocation)
    const categoryTypes = useSelector((state) => state.schedule.categoryTypes);
    const user = useSelector(state => state.user.user)
    const [value, setValue] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [optionsSorted, setOptionsSorted] = useState(null);
    const isAsync = useMemo(() => (!options && (type === USERS_SELECT || type === ITEMS_SELECT)), [options, type]);
    const currencySymbol = useMemo(() => selectedLocation ? selectedLocation.currency_symbol : '',[selectedLocation])
    const dispatch = useDispatch()
    const selectRef = useRef()
    const {has} = usePermissions()
    const {hasBookingServicesPermission} = useCheckPermission();
    const isBookingType = Object.values(BoxCategoryTypes).includes(categoryTypeId);

    useEffect(() => {
        if(initValue !== null && initValue !== undefined && (initValue !== value?.value)) {
            let valueObject;
            if(isAsync) {
                if (asyncInit) {
                    valueObject = initValue
                } else {
                    valueObject = optionsSorted?.reduce((acc, type) => [...acc, ...type.options], []).find(item => item.value === initValue)
                }
            } else if (rest.isMulti) {
                valueObject = options?.filter(item => initValue.find(iv => iv.value === item.value))
            } else {
                valueObject = options?.find(item => item.value === initValue)
            }
            setValue(valueObject)
        }
        if(initValue === null) setValue(null)
    }, [initValue, options, optionsSorted])

    useEffect(() => {
        getTypeOptionComponent()
    }, [type]);

    useEffect(() => {
        if(options) setOptionsSorted(dontSort ? options : sortOptions(options))
    }, [options]);

    useEffect(() => {
        if(createEventTypeModalRes && type === CATEGORY_SELECT) {
            onChange(createEventTypeModalRes)
            dispatch(updateModalRes({modalName: CREATE_EVENT_TYPE_MODAL, res: null}))
        }
    }, [createEventTypeModalRes]);

    useEffect(() => {
        if(createEventModalRes && type === STAFF_SELECT && createEventModalRes?.flowName === flowOptions.STAFF_MEMBER) {
            onChange({...createEventModalRes, value: createEventModalRes.id, label: `${createEventModalRes.first_name} ${createEventModalRes.last_name}`})
            dispatch(updateModalRes({modalName: CREATE_EVENT_MODAL, res: null}))
        }
        if(createEventModalRes && type === SPACE_SELECT && createEventModalRes?.flowName === flowOptions.SPACE_FLOW) {
            onChange({...createEventModalRes, value: createEventModalRes.id, label: createEventModalRes.name})
            dispatch(updateModalRes({modalName: CREATE_EVENT_MODAL, res: null}))
        }
        if(createEventModalRes && type === TASK_TYPES_SELECT) {
            onChange({...createEventModalRes, value: createEventModalRes.id, label: createEventModalRes.type})
            dispatch(updateModalRes({modalName: CREATE_EVENT_MODAL, res: null}))
        }
    }, [createEventModalRes]);

    const getTypeOptionComponent = (args) => {
        if(args) {
            switch(type) {
                case CATEGORY_SELECT:
                    return getCategoryOptionStyle(args)
                case USERS_SELECT:
                case COACH_SELECT:
                case STAFF_SELECT:
                    return getUsersOptionStyle(args)
                case MESSAGE_TEMPLATE_SELECT:
                    return getMessageTemplateOptionStyle(args)
                case TASK_TYPES_SELECT:
                    return has('viewTasksV5') ? getTaskTypeOptionStyle(args) :
                        <AppText fontSize={fontSize ? fontSize : GlobalStyleAttributes.fonts.standard16}>{args?.label}</AppText>
                default:
                    return <AppText fontSize={fontSize ? fontSize : GlobalStyleAttributes.fonts.standard16}>{args?.label}</AppText>
            }
        }
    }

    const getCategoryOptionStyle = ({label, membership_types, category_color}) => {
        return (
            <HStack alignItems={'center'} space={'0.5rem'}>
                <HStack space={'0.5rem'} alignItems={'center'}>
                    <Circle bg={category_color} size={'20px'}/>
                    <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{label}</AppText>
                </HStack>
                {
                    membership_types && membership_types.length > 0 &&
                    <HStack space={'0.5rem'} alignItems={'center'}>
                        <Divider orientation="vertical" bg={Colors.newDesign.darkGrey} thickness="1" h={"15px"}/>
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16} color={Colors.newDesign.darkGrey}>{currencySymbol} {membership_types[0].price.toLocaleString()}</AppText>
                    </HStack>
                }
            </HStack>
        )
    }

    const getTaskTypeOptionStyle = ({label, task_type_color}) => {
        return (
            <HStack space={'0.5rem'} alignItems={'center'}>
                <Circle bg={task_type_color || '#D0FFF9'} size={'20px'}/>
                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{label}</AppText>
            </HStack>
        )
    }

    const getUsersOptionStyle = ({label, cloudinary_image, first_name, last_name, user_fk}) => {
        label = label ? label.replace('null', '') : label
        return (
            <HStack alignItems={'center'} space={'0.5rem'} overflowX={'hidden'}>
                <UserAvatar imageUri={cloudinary_image} size={25} firstName={first_name} lastName={last_name}/>
                <HStack space={'0.5rem'}>
                    <AppText fontSize={fontSize ? fontSize : GlobalStyleAttributes.fonts.standard16}>{label}</AppText>
                    <AppText fontSize={fontSize ? fontSize : GlobalStyleAttributes.fonts.standard16} color={Colors.newDesign.darkGrey}>{user_fk === user.user_fk ? `(${t('assign-to-me')})` : ''}</AppText>
                </HStack>
            </HStack>
        )
    }

    const getUserOptionComponent = ({label, cloudinary_image, first_name, last_name, user_fk, rivhit_customer_id, active, type}) => {
        label = label ? label.replace('null', '') : label
        return (
            <HStack my={'0.5rem'} justifyContent={'space-between'} alignItems={'center'}>
                <HStack alignItems={'center'} space={'0.5rem'}>
                    <UserAvatar imageUri={cloudinary_image} size={25} firstName={first_name} lastName={last_name} />
                    <HStack space={'0.5rem'}>
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{label}</AppText>
                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16} color={Colors.newDesign.darkGrey}>{user_fk === user.user_fk ? `(${t('assign-to-me')})` : ''}</AppText>
                    </HStack>
                </HStack>
                {rivhit_customer_id && !hideCustomerId && <AppText fontSize={GlobalStyleAttributes.fonts.infoText} color={Colors.newDesign.darkGrey}>{t('customerId')}: {rivhit_customer_id}</AppText>}
                {type !== "lead" && <AppText style={{ backgroundColor: active ? Colors.newDesign.lightBlue : Colors.borderColorInput, borderRadius: '4px', textAlign: 'center'}} height={'1.5rem'} width={'3.75rem'}>{active ? t('active') : t('inactive')}</AppText>}
            </HStack>
        )
    }

    const getMessageTemplateOptionStyle = ({label, digital_form_id}) => {
        return <Flex gap={'0.3rem'} align='center'>
                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{label}</AppText>
                {digital_form_id &&<FontAwesomeIcon icon={light('file')} size={GlobalConsts.iconSize.XS} />}
        </Flex>
    }

    const getItemOptionComponent = ({label, cloudinary_image, first_name, last_name, user_fk, rivhit_customer_id, active, type}) => {
        label = label ? label.replace('null', '') : label
        return (
            <HStack my={'0.5rem'} alignItems={'center'}>
                <AppText fontSize={GlobalStyleAttributes.fonts.standard16}>{label}</AppText>
            </HStack>
        )
    }

    const getOptionComponent = (type) => {
        switch (type) {
            case USERS_SELECT:
                return getUserOptionComponent
            case ITEMS_SELECT:
                return getItemOptionComponent

        }
    }

    const ClearIndicator = props => {
        return (
            <components.ClearIndicator {...props}>
                <CustomButtonV2 type={'link'}
                                iconName={'fa-xmark'}
                                id={'select-clear-button'}
                                tooltipText={t('clear')}
                />
            </components.ClearIndicator>
        );
    };


      const MultiValueRemove = props => {
        return (
          <components.MultiValueRemove {...props}>
            <FontAwesomeIcon icon={light('x')} color={Colors.newDesign.darkGrey} size={GlobalConsts.iconSize.XXS}/>
          </components.MultiValueRemove>
        );
      };

    const onBtnClick = () => {
        setIsMenuOpen(false)
        selectRef.current?.blur()
        switch(type) {
            case CATEGORY_SELECT:
                sendDataEvent('click create another service type');
                const type = categoryTypes.find(type => type.id === categoryTypeId ?? options[0].type)
                dispatch(openModal({ modalName: CREATE_EVENT_TYPE_MODAL, props: { target: null, type: type.name }}))
                break;
            case COACH_SELECT:
                sendDataEvent('click create another staff member');
                openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL, props: {initSelection: flowOptions.STAFF_MEMBER, target, initProps: {roles: [StaffMemberRoles.COACH]}}})
                break;
            case STAFF_SELECT:
                sendDataEvent('click create another staff member');
                openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL, props: {initSelection: flowOptions.STAFF_MEMBER, target}})
                break;
            case LEAD_STATUS_SELECT:
                dispatch(openModal({ modalName: SETTINGS_VALUE_MODAL, props: {type: LEAD_STATUS_TYPE, onChange}}))
                break;
            case LEAD_SOURCE_SELECT:
                dispatch(openModal({ modalName: SETTINGS_VALUE_MODAL, props: {type: LEAD_SOURCE_TYPE, onChange}}))
                break;
            case SPACE_SELECT:
                sendDataEvent('click create another space');
                openStepperModal({ dispatch, modalName: CREATE_EVENT_MODAL, props: {initSelection: flowOptions.SPACE_FLOW }})
                break;
            case TASK_TYPES_SELECT:
                openStepperModal({
                    dispatch, modalName: CREATE_EVENT_MODAL,
                    props: {initSelection: flowOptions.TASK_TYPE_FLOW, target: null},
                })
                break;
            case  LOST_REASONS_SELECT:
                dispatch(openModal({ modalName: SETTINGS_VALUE_MODAL, props: {type: LOST_REASONS_TYPE, onChange}}))
                break;
            // case CUSTOM_FIELD_SELECT:
            //     sendDataEvent('click create another custom field');
            //     dispatch(openModal({modalName: CREATE_EVENT_MODAL, props: { initSelection: flowOptions.CUSTOM_FIELD, target: target}}))
            //     break;
        }
    }

    const fetchUsersFromInput = (input, callback) => { // dont touch this! this is the only way asyncSelect agreed to work!!!!
        if (input.length > 1) {
            fetchUsers(input).then((res) => callback(res))
        }
    }

    const fetchUsers = async (input) => {
        setIsLoading(true);
        const results = await fetchApi('usersBoxes/searchForMember', 'post', { input: input }, false, true);
        if (results) {
            let filteredResults = results.filter(item => {
                return  item.first_name && (item.first_name.trim() !== "") &&
                        item.type !== Roles.UNION.toLowerCase() &&
                        (allowInactive || item.type === Roles.LEAD.toLowerCase() ? true : item.active) &&
                        (hideLeadType ? item.type !== Roles.LEAD.toLowerCase() : true) &&
                        (hideFreefitType ? item.type !== Roles.AGGREGATOR.toLowerCase() : true) &&
                        (hideArchived ? !item.deleted_at : true)
            }).reduce((acc, item) => [...acc, {...item, label: item.full_name, value: item.user_fk}],[]);

            filteredResults = sortOptions(filteredResults)
            filteredResults = groupUsers(filteredResults)
            setOptionsSorted(filteredResults)
            setIsLoading(false);
            return filteredResults
        }
        setIsLoading(false);
        return []
    }

    const asyncFetchUsers = useCallback(debounce(fetchUsersFromInput, 300),[hideLeadType, hideFreefitType])

    const fetchItemsFromInput = (input, callback) => {
        if (input.length > 1) {
            fetchItems(input).then((res) => callback(res))
        }
    }

    const fetchItems = async (input) => {
        setIsLoading(true);
        const results = await fetchApi('membership/searchForMembershipTypes', 'post', { input: input, show_in_app: showInApp }, false, true);
        if (results) {
            let filteredResults = results.reduce((acc, item) => [...acc, {...item, label: item.name, value: item.id}],[]);

            // filteredResults = sortItemOptions(filteredResults)
            filteredResults = groupItems(filteredResults)
            setOptionsSorted(filteredResults)
            setIsLoading(false);
            return filteredResults
        }
        setIsLoading(false);
        return []
    }

    const asyncFetchItems = useCallback(debounce(fetchItemsFromInput, 300),[showInApp])


    const fetchFunction = (type) => {
        switch (type) {
            case USERS_SELECT:
                return asyncFetchUsers
            case ITEMS_SELECT:
                return asyncFetchItems
        }
    }

    const sortItemOptions = () => {

    }

    const groupItems = (options) => {
        let group = groupBy(options, 'type')
        const res = Object.keys(group).reduce((acc, groupKey) => ({...acc, [groupKey]: {...acc[groupKey], options: group[groupKey]}}),
            {
                'plan': {
                    label: t('planItemsSelect'),
                    options: []
                },
                'session': {
                    label: t('sessionItemsSelect'),
                    options: []
                },
                'item': {
                    label: t('productItemsSelect'),
                    options: []
                }
            })
        return Object.values(res);
    }

    const sortOptions = (options) => {
        const ordered = orderBy(options, ['active',item => item?.label?.toLowerCase()], ['desc', 'asc'])
        return sortBy(ordered, (item) => item?.user_fk === user.user_fk ? 0 : 1); // if logged in user is an option, move it to first place
    }

    const groupUsers = (options) => {
        let group = groupBy(options, 'type')
        const res = Object.keys(group).reduce((acc, groupKey) => ({...acc, [groupKey]: {...acc[groupKey], options: group[groupKey]}}),
            {
                'user': {
                    label: t('members'),
                    options: []
                },
                'aggregator': {
                    label: t('freefit'),
                    options: []
                },
                'union': {
                    label: t('unions'),
                    options: []
                },
                'lead': {
                    label: t('leads'),
                    options: []
                },
                'coach': {
                    label: t('staff'),
                    options: []
                }
            })
        return Object.values(res);
    }

    const closeMenu = () => {
        setTimeout(() => setIsMenuOpen(false), isMobile ? 500 : 0)
    }

    return (
        <>
            {isAsync ?
                <AsyncSelect isLoading={isLoading}
                             value={value}
                             loadOptions={fetchFunction(type)}
                             onChange={onChange}
                             isSearchable={true}
                             openMenuOnClick={false}
                             defaultOptions={value ? [{label: value.type, options: [value]}] : null}
                             defaultValue={value}
                             formatOptionLabel={getOptionComponent(type)}
                             isRtl={isRTL()}
                             menuShouldScrollIntoView={false}
                             styles={{
                                 control: (baseStyles, state) => ({...baseStyles, fontFamily: 'Ploni', cursor: 'pointer', borderRadius: 4,
                                    borderColor: isError ? Colors.newDesign.attendanceAbsentText : state.isFocused ? Colors.newDesign.mainBlue : Colors.borderColor,
                                    boxShadow: 'none',
                                    "&:hover": {
                                        borderColor: isError ? Colors.newDesign.attendanceAbsentText : Colors.newDesign.mainBlue
                                    },
                                }),
                                 option: (baseStyles, state) => ({
                                     ...baseStyles,
                                     backgroundColor: state.isSelected ? Colors.borderColor : state.isFocused ? Colors.newDesign.menuBar : Colors.newDesign.white,
                                     borderBottom: `1px ${Colors.borderColor} solid`,
                                     direction: isRTL() ? 'rtl' : 'ltr'
                                 }),
                                 menuPortal: base => ({...base, zIndex: 9999, fontFamily: 'Ploni'}),
                                 group: base => ({...base, padding: 0}),
                                 groupHeading: base => ({
                                     ...base,
                                     margin: 0,
                                     padding: "5px 10px",
                                     fontSize: GlobalStyleAttributes.fonts.infoText,
                                     backgroundColor: Colors.newDesign.greyBg,
                                     textTransform: "capitalize",
                                     color: Colors.newDesign.mainText
                                 }),
                             }}
                             menuPortalTarget={document.body}
                             placeholder={placeholder || t('search-user')}
                             components={{ DropdownIndicator: null, IndicatorSeparator: null, ClearIndicator }}
                             {...rest}
                />
                :
                <Select options={optionsSorted}
                        value={value}
                        onChange={onChange}
                        onMenuOpen={() => setIsMenuOpen(true)}
                        onMenuClose={closeMenu}
                        components={{Menu: (props) => <Menu type={type} onBtnClick={onBtnClick} hideCreateBtn={hideCreateBtn || (isBookingType && !hasBookingServicesPermission)} {...props}/>, ClearIndicator, IndicatorSeparator: null, MultiValueRemove}}
                        isSearchable={true}
                        formatOptionLabel={getTypeOptionComponent}
                        menuIsOpen={isMenuOpen}
                        ref={selectRef}
                        placeholder={placeholder || t('choose')}
                        filterOption={createFilter({matchFrom: 'any'})}
                        menuShouldScrollIntoView={false}
                        styles={{
                            container: base => ({
                                ...base,
                                ...customStyle
                            }),
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: 'Ploni',
                                fontSize,
                                backgroundColor: Colors.newDesign.white,
                                opacity: state.isDisabled ? 0.5 : 1,
                                cursor: 'pointer',
                                borderRadius: 4,
                                borderColor: isError ? Colors.newDesign.attendanceAbsentText : state.isFocused ? Colors.newDesign.mainBlue : Colors.borderColor,
                                boxShadow: 'none',
                                "&:hover": {
                                    borderColor: isError ? Colors.newDesign.attendanceAbsentText : Colors.newDesign.mainBlue
                                },
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: (state.isSelected && !state.isDisabled) ? Colors.borderColor : state.isFocused ? Colors.newDesign.menuBar : Colors.newDesign.white,
                                opacity: state.isDisabled ? 0.5 : 1,
                                direction: isRTL() ? 'rtl' : 'ltr'
                            }),
                            menuPortal: base => ({...base, zIndex: 9999, fontFamily: 'Ploni'}),
                            multiValue: (baseStyles) => ({
                                ...baseStyles,
                                paddingInlineStart: '0.3rem',
                                backgroundColor: Colors.newDesign.menuBar,
                                "&:hover": {
                                    backgroundColor: Colors.newDesign.greyBg
                                },
                            }),
                            multiValueRemove: (baseStyles) => ({
                                ...baseStyles,
                                marginInlineEnd: '0.3rem',
                                backgroundColor: 'transparent',
                                "&:hover": {
                                    backgroundColor: 'transparent'
                                },
                            })
                        }}
                        menuPortalTarget={document.body}
                        isRtl={isRTL()}
                        {...rest}
                />
            }
        </>
    );
};

export default AdvanceSelect;

const Menu = (props) => {
    const { type, onBtnClick, children, hideCreateBtn, ...rest} = props;
    const [btnText, setBtnText] = useState(null);
    const { has } = usePermissions()
    useEffect(() => {
        getBtnText()
    }, [type]);

    const getBtnText = () => {
        let text = null;
        switch(type) {
            case CATEGORY_SELECT:
                text = t('createAnother')
                break;
            case COACH_SELECT:
            case STAFF_SELECT:
                text = has('addCoach') ? t('createAnother') : null
                break;
            case LEAD_SOURCE_SELECT:
                text = has('boxSourceActions') ? t('createAnother') : null
                break;
            case LEAD_STATUS_SELECT:
                text = has('boxStatusActions') ? t('createAnother') : null
                break;
            case LOST_REASONS_SELECT:
                text = has('boxStatusActions') ? t('createAnother') : null
                break;
            case TASK_TYPES_SELECT:
                text = has('taskTypeActions') ? t('createAnother') : null
                break;
            case SPACE_SELECT:
                text = has('createSpace') ? t('createAnother') : null
                break;
            // case CUSTOM_FIELD_SELECT:
            //     text = has('addCustomField') || true ? t('createAnother') : null
            //     break;
        }
        setBtnText(text)
    }

    return (
        <components.Menu {...rest}>
            {children}
            { btnText && !hideCreateBtn &&
                <CustomButtonV2 onClick={onBtnClick}
                                override={() =>
                                    <VStack space={'1rem'} p={'1rem'} w={'100%'}>
                                        <Divider orientation="horizontal" bg={Colors.borderColor} thickness="1" w={'100%'}/>
                                        <AppText fontSize={GlobalStyleAttributes.fonts.standard16} color={Colors.newDesign.mainBlue} style={{writingDirection: isRTL() ? 'rtl' : 'ltr'}}>+ {btnText}</AppText>
                                    </VStack>}
                />
            }
        </components.Menu>
    );
};
