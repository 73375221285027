import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchApi } from '../services/HTTP';
import { logout } from './authSlice';
import {hideLoader, showLoader} from './rootManagerSlice';
import {CREATE_EVENT_MODAL, updateModalRes} from "./modalManagerSlice";
import {t} from "../services/i18n";

const boxSlice = createSlice({
  name: "box",
  initialState: {
    loading: false,
    box: { counters: { tasks: 0, finance: 0 } },
    error: '',
  },
  reducers: {
    setBox: (state, action) => {
      state.box = Object.assign(state.box, action.payload);
    },
    setCounters: (state, action) => {
      state.box.counters = action.payload;
    },
    setReportsSettings: (state, action) => {
      state.box.reportsSettings = action.payload;
    },
    updateBox: (state, action) => {
      state.box = { ...state.box, ...action.payload }
    },
    updateAllLocations: (state, action) => {
      const newLocations = state.box.locations_box.reduce((acc, location) => [...acc, {...location, ...action.payload}], [])
        state.box = {...state.box, locations_box: newLocations}
    },
    updateLocations: (state, action) => {
      state.box = {...state.box, locations_box: action.payload}
    },
    updateTaskTypes: (state, action) => {
      state.box.task_types = action.payload
    },
    updateAutoTasks: (state, action) => {
      state.box.auto_actions = action.payload
    }
  },
  extraReducers: builder => {
    /** LEAD STATUS */
    builder.addCase(saveLeadStatus.pending, (state) => {
      state.loading = true
    })
    builder.addCase(saveLeadStatus.fulfilled, (state, data) => {
      state.loading = false
      state.error = ''
      state.box.box_statuses = [ ...state.box.box_statuses, {...data.payload, active: 1} ]
    })
    builder.addCase(saveLeadStatus.rejected, (state, data) => {
      state.loading = false
      state.error = data.payload?.error
    })
    /** LEAD SOURCE */
    builder.addCase(saveLeadSource.pending, (state) => {
      state.loading = true
    })
    builder.addCase(saveLeadSource.fulfilled, (state, data) => {
      state.loading = false
      state.error = ''
      state.box.box_sources = [ ...state.box.box_sources, {...data.payload, active: 1} ]
    })
    builder.addCase(saveLeadSource.rejected, (state, data) => {
      state.loading = false
      state.error = data.payload?.error
    })
    builder.addCase(saveTaskType.pending, (state) => {
      state.loading = true
    })
    builder.addCase(saveTaskType.fulfilled, (state, data) => {
      state.loading = false
      state.error = ''
      const type = state.box.task_types.find(type => type.id === data.payload.id)
      state.box.task_types = type ? [...state.box.task_types.map(taskType => taskType.id === data.payload.id ? data.payload : taskType)] : [...state.box.task_types, {...data.payload, active: 1}]

    })
    builder.addCase(saveTaskType.rejected, (state, data) => {
      state.loading = false
      state.error = data.payload?.error
    })
    /** LOST LEAD REASON */
    builder.addCase(saveLostLeadReason.pending, (state) => {
      state.loading = true
    })
    builder.addCase(saveLostLeadReason.fulfilled, (state, data) => {
      state.loading = false
      state.error = ''
      state.box.lead_lost_reasons = [ ...state.box.lead_lost_reasons, {...data.payload, active: 1} ]
    })
    builder.addCase(saveLostLeadReason.rejected, (state, data) => {
      state.loading = false
      state.error = data.payload?.error
    })

    /** REPORTS SETTINGS */
    builder.addCase(saveReportsSettings.pending, (state, action) => {
      const {reportName} = action.meta.arg
      state.box.loadingReports = {...(state.box.loadingReports || {}), [reportName]: true}
    })
    builder.addCase(saveReportsSettings.fulfilled, (state, data) => {
      state.box.loadingReports = {...(state.box.loadingReports || {}), [data.payload.reportName]: false}
      state.error = ''
      state.box.reportsSettings = data.payload
    })
    builder.addCase(saveReportsSettings.rejected, (state, data) => {
      state.box.loadingReports = {...(state.box.loadingReports || {}), [data.payload.reportName]: false}
      state.error = data.payload?.error
    })
    /** GET BOX DATA */
    builder.addCase(getBox.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getBox.fulfilled, (state, action) => {
      state.loading = false
      state.error = ''
      state.box = Object.assign(state.box, action.payload);
    })
    builder.addCase(getBox.rejected, (state, action) => {
      console.log('getBox error', action);
      state.loading = false
      state.error = action.payload?.error
      state.box = { counters: { tasks: 0, finance: 0 } }
    })
    builder.addCase(isBoxHasNewSite.pending, (state) => {
      state.loading = true
    })
    builder.addCase(isBoxHasNewSite.fulfilled, (state, data) => {
      state.loading = false
      state.box.isBoxHasNewSite = !data.payload
    })
    builder.addCase(isBoxHasNewSite.rejected, (state, data) => {
      state.loading = false
      state.error = data.payload?.error
    })
    builder.addCase(getAutoTasks.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAutoTasks.fulfilled, (state, data) => {
      state.loading = false
      state.error = ''
      state.box.auto_actions = data.payload.filter(item => item.task_type_id).map(item => {
        if (item.case === 'IMMEDIATE') {
          item.timeStr = t('immediateAutomaticAction')
          item.hourStr = '';
        } else {
          let timeStr = item.period_amount + ' ' + (item?.auto_actions?.action === 'CLASS_END' ? t('classes') + ' ' : t(item.period_time_unit + 'automaticActionTable') + ' ');
          let str = '';
          if (item.before_after === 'AFTER') {
            str = t('afterAutomticActionTable') + ' ' + timeStr;
          } else {
            str = timeStr + t('before');
          }
          item.timeStr = item.period_amount === 0 ? t('immediateAutomaticAction') : str;
          item.hourStr = item.hour
              ? item.hour.split(':').slice(0, -1).join(':')
              : null;
        }
        return item
      })

    })
    builder.addCase(getAutoTasks.rejected, (state, data) => {
      state.loading = false
      state.error = data.payload?.error
    })
    builder.addCase(checkBoxFreeFitPermission.fulfilled, (state, data) => {
      state.loading = false
      state.box.hasFreeFitPermission = data.payload.length > 0 ? true : false
    })
    builder.addCase(checkBoxFreeFitPermission.pending, (state, data) => {
      state.loading = true
    })
    builder.addCase(checkBoxFreeFitPermission.rejected, (state, data) => {
      state.loading = false
      state.error = data.payload?.error
    })
    builder.addCase(getBoxSettings.fulfilled, (state, data) => {
      state.loading = false
      state.error = ''
      state.box.settings = data.payload
    })
    builder.addCase(getBoxSettings.rejected, (state, data) => {
      state.loading = false
      state.error = data.payload?.error
    })
  }
});

export const { setBox, setCounters, updateBox, updateAllLocations, updateLocations, setReportsSettings, updateTaskTypes, updateAutoTasks } = boxSlice.actions;

export default boxSlice.reducer;


export const saveLeadStatus = createAsyncThunk('saveLeadStatus', async (params, { rejectWithValue }) => {
  return await fetchApi('boxes/leadStatus', 'POST', params, false, true);
})

export const saveLostLeadReason = createAsyncThunk('saveLostLeadReason', async (params, { rejectedWithValue }) => {
  return await fetchApi('boxes/lostLeadReason', 'POST', params, false, true);
})

export const saveLeadSource = createAsyncThunk('saveLeadSource', async (params, { rejectWithValue }) => {
  return await fetchApi('boxes/leadSource', 'POST', params, false, true);
})

export const saveTaskType = createAsyncThunk('saveTaskType', async (params, { rejectWithValue, dispatch, getState }) => {
  dispatch(showLoader())
  const res = await fetchApi('boxes/taskType', 'POST', params, false, true);
  dispatch(hideLoader());
  if(getState().modalManager.createEventModal.isOpen) {
    dispatch(updateModalRes({modalName: CREATE_EVENT_MODAL, res: res}))
  }
  return res
})

export const saveReportsSettings = createAsyncThunk('saveReportsSettings', async (params, { rejectWithValue, dispatch }) => {
 await fetchApi('reports/settings', 'POST', params, false, true);
  return params;
})

export const getBox = createAsyncThunk('getBox', async (params, { rejectWithValue, dispatch }) => {
  try {
    return await fetchApi('getBoxData', 'GET', null, false, true);
  } catch (e) {
    console.log('getBox error', e);
    if (e?.statusCode == 401) {
      dispatch(hideLoader());
      dispatch(logout());
    }
    return rejectWithValue(e);
  }
})

export const isBoxHasNewSite = createAsyncThunk('isBoxHasNewSite', async (params, { rejectedWithValue,dispatch }) => {
  return await fetchApi(`site/boxes/isFirstPay/${params.identifier}`, 'GET', null, true, true)

})

export const getAutoTasks = createAsyncThunk('getAutoTasks', async  (params, { rejectedWithValue }) => {
  return await fetchApi(`autoActionsSet/getAutoActionsSetByBoxId`, 'POST', {}, false, true)
})

export const checkBoxFreeFitPermission = createAsyncThunk('CheckBoxFreeFitPermission', async  (params, { rejectedWithValue }) => {
  return await fetchApi(`boxes/checkBoxFreeFitPermission`, 'POST', {}, false, true)

})

export const getBoxSettings = createAsyncThunk('getBoxSettings', async (params, { rejectedWithValue }) => {
  return await fetchApi(`boxes/settings`, 'GET', {}, false, true)
})

export const setBoxSettings = createAsyncThunk('setBoxSettings', async (params, { rejectedWithValue }) => {
  return await fetchApi(`boxes/settings`, 'POST', params, false, true)
})
