import {langCode, t} from "../../../../../services/i18n";
import EventTypeStep from "./Steps/EventTypeStep";
import React, {useMemo} from "react";
import BasicInformation from "./Steps/BasicInformation";
import * as yup from "yup";
import {addToSelectionsTree} from "../../../../../redux/stepperSlice";
import {store} from "../../../../../redux/store";
import moment from "moment";
import RepeatConfiguration from "./Steps/RepeatConfiguration";
import {dbDateFormat, dbDateTimeFormat, dbTimeFormat, getPenaltiesTags, getSettingsObjectByType, isActive, isBoxInTrial, isStringWithoutEmoji, penaltiesDefaultMessage, snakeToCamel, targetAudienceTasks} from "../../../../../services/Helpers";
import SessionSettings from "./Steps/SessionSettings";
import SuccessStep from "../../../../common/SuccessStep";
import {flowOptions, getSuccessProps} from "./FlowOptions";
import MultiDayConfiguration from "./Steps/MultiDayConfiguration";
import OneOnOneType from "./Steps/OneOnOneType";
import UserAndMembershipSelection from "./Steps/UserAndMembershipSelection";
import PaymentMethod from "./Steps/PaymentMethod";
import {
    EMAIL,
    APPOINTMENT,
    ENTER_DEBT,
    FREE_PURCHASE,
    messageAudience,
    PUSH,
    SERIES_FUTURE_SESSIONS_EDIT_TYPE,
    SINGLE_SESSION_EDIT_TYPE,
    SMS,
    WHATSAPP,
    SCHEDULE_TYPE, USER_TYPE, customFieldTypes,
    HOK, TRIAL, IMMEDIATE, seriesPaymentDBTranslate,
    boxSettingsProps,
    USER_FIELDS
} from "../../../../../services/ConstHelper";
import AvailabilitySlotSelection from "./Steps/AvailabilitySlotSelection";
import EditMultiDayConfig from "./Steps/EditMultiDayConfig";
import SpaceConfiguration from "./Steps/SpaceConfiguration";
import MemberDetails from "../../../Forms/MemberDetails";
import StaffMemberRoleSelection from "../../../Forms/StaffMemberRoleSelection";
import { emailRegex, validateAfterEmailSymbol } from "../../../../../services/validationService";
import { MembershipTypes, StaffMemberRoles } from "../../../../../Configs/DatabaseConsts";
import { isEmailAvailable } from "../../../../../redux/UserFlowSlice";
import BlockedTime from "./Steps/BlockedTime";
import MessageType from "../../../Forms/MessageType";
import MessageDetails from "../../../Forms/MessageDetails";
import EmailAfterPurchase from "../../../Forms/EmailAfterPurchase";
import PenaltySettings from "../../../Forms/PenaltySettings";
import PenaltyExtraProperties from "../../../Forms/PenaltyExtraProperties";
import PenaltySummary from "../../../Summaries/PenaltySummary";
import TaskDetails from "../../../Forms/TaskDetails";
import SendConvertLink from "../../../Forms/SendConvertLink";
import CustomFieldSelection from "./Steps/CustomFieldSelection";
import CustomFieldSettings from "./Steps/CustomFieldSettings";
import uniqBy from "lodash-es/uniqBy";
import SeriesPaymentOptions from "./Steps/SeriesPaymentOptions";
import HugPaymentsOptions from "./Steps/HugPaymentsOptions";
import Payment from "./Steps/Payment";
import PersonalDetails from "./Steps/PersonalDetails";
import CreateTaskType from "../../../../../../web/src/components/Tasks/TasksSettings/CreateTaskType";
import SendMembershipLink from "../../../Forms/SendMembershipLink";
import RequiredFieldsSettings from "../../../Forms/RequiredFieldsSettings";

export const optionalSteps = {
    EVENT_TYPE: 'EVENT_TYPE',
    APPOINTMENT_OR_AVAILABILITY_STEP: 'APPOINTMENT_OR_AVAILABILITY_STEP',
    BASIC_INFO: 'BASIC_INFO',
    REPEAT_CONFIG: 'REPEAT_CONFIG',
    AVAILABILITY_CONFIG: 'AVAILABILITY_CONFIG',
    MULTI_DAY_CONFIG: 'MULTI_DAY_CONFIG',
    SETTINGS: 'SETTINGS',
    USER_AND_MEMBERSHIP_SELECTION: 'USER_AND_MEMBERSHIP_SELECTION',
    PAYMENT_METHOD_SELECTION: 'PAYMENT_METHOD_SELECTION',
    SUCCESS_PAGE: 'SUCCESS_PAGE',
    EDIT_MULTI_DAY_CONFIG: 'EDIT_MULTI_DAY_CONFIG',
    MEMBER_DETAILS: 'MEMBER_DETAILS',
    STAFF_MEMBER_ROLE_SELECT: 'STAFF_MEMBER_ROLE_SELECT',
    BLOCKED_TIME: 'BLOCKED_TIME',
    MESSAGE_TYPE: 'MESSAGE_TYPE',
    MESSAGE_DETAILS: 'MESSAGE_DETAILS',
    EMAIL_AFTER_PURCHASE: 'EMAIL_AFTER_PURCHASE',
    SPACE_CONFIGURATION: 'SPACE_CONFIGURATION',
    PENALTY_SETTINGS: 'PENALTY_SETTINGS',
    PENALTY_EXTRA_PROPERTIES: 'PENALTY_EXTRA_PROPERTIES',
    PENALTY_SUMMARY: 'PENALTY_SUMMARY',
    TASK_DETAILS: 'TASK_DETAILS',
    TASK_TYPE: 'TASK_TYPE',
    SEND_CONVERT_LINK: 'SEND_CONVERT_LINK',
    CUSTOM_FIELD_TYPE_SELECTION: 'CUSTOM_FIELD_TYPE_SELECTION',
    CUSTOM_FIELD_SETTINGS: 'CUSTOM_FIELD_SETTINGS',
    SERIES_PAYMENT_OPTIONS: 'SERIES_PAYMENT_OPTIONS',
    CHOOSE_HUG_PAYMENT_TYPE: 'CHOOSE_HUG_PAYMENT_TYPE',
    PAYMENT: 'PAYMENT',
    SEND_ONE_TIME_MEMBERSHIP_LINK: 'SEND_ONE_TIME_MEMBERSHIP_LINK',
    PERSONAL_DETAILS: 'PERSONAL_DETAILS',
    REQUIRED_FIELDS_SETTINGS: 'REQUIRED_FIELDS_SETTINGS'
}

export const renderStep = {
    EVENT_TYPE: (props) => renderEventTypeSelectionStep(props),
    APPOINTMENT_OR_AVAILABILITY_STEP: (props) => renderAppointmentOrAvailabilityStep(props),
    BASIC_INFO: (props) => renderBasicInfoStep(props),
    REPEAT_CONFIG: (props) => renderRepeatConfigStep(props),
    AVAILABILITY_CONFIG: (props) => renderAvailabilityConfigStep(props),
    MULTI_DAY_CONFIG: (props) => renderMultiDayConfigStep(props),
    SETTINGS: (props) => renderSettingsStep(props),
    USER_AND_MEMBERSHIP_SELECTION: (props) => renderUserAndMembershipSelection(props),
    PAYMENT_METHOD_SELECTION: (props) => renderPaymentMethodSelection(props),
    SUCCESS_PAGE: (props) => renderSuccessStep(props),
    EDIT_MULTI_DAY_CONFIG: (props) => renderEditMultiDayConfig(props),
    MEMBER_DETAILS: (props) => renderMemberDetails(props),
    STAFF_MEMBER_ROLE_SELECT: (props) => renderStaffMemberRoleSelect(props),
    BLOCKED_TIME: (props) => renderBlockedTime(props),
    MESSAGE_TYPE: (props) => renderMessageTypeSelect(props),
    MESSAGE_DETAILS: (props) => renderMessageDetails(props),
    EMAIL_AFTER_PURCHASE: (props) => renderEmailAfterPurchase(props),
    SPACE_CONFIGURATION: (props) => renderSpaceConfigStep(props),
    PENALTY_SETTINGS: (props) => renderPenaltySettingsConfigStep(props),
    PENALTY_EXTRA_PROPERTIES: (props) => renderPenaltyExtraPropertiesConfigStep(props),
    PENALTY_SUMMARY: (props) => renderPenaltySummaryStep(props),
    TASK_TYPE: (props) => renderTaskTypeStep(props),
    TASK_DETAILS: (props) => renderTaskDetailsStep(props),
    SEND_CONVERT_LINK: (props) => renderSendConvertLinkStep(props),
    CUSTOM_FIELD_TYPE_SELECTION: (props) => renderCustomFieldTypeSelection(props),
    CUSTOM_FIELD_SETTINGS: (props) => renderCustomFieldSettings(props),
    SERIES_PAYMENT_OPTIONS: (props) => renderSeriesPaymentOptionsStep(props),
    CHOOSE_HUG_PAYMENT_TYPE: (props) => renderChoosePaymentOptionsStep(props),
    PAYMENT: (props) => renderPayment(props),
    SEND_ONE_TIME_MEMBERSHIP_LINK: (props) => renderSendOneTimeMembershipLink(props),
    PERSONAL_DETAILS: (props) => renderPersonalDetails(props),
    REQUIRED_FIELDS_SETTINGS: (props) => renderRequiredFieldsSettings(props)
}

export const stepHeader = () => {
    const flowConfig = store.getState().stepper
    const isEdit = flowConfig.editType !== null
    const prefix = isEdit ? (flowConfig.editType === SINGLE_SESSION_EDIT_TYPE ? 'edit-a' : 'edit-all') : 'add-a'
    const basicLabel = t(`${prefix}-${flowConfig.selectionsTree[optionalSteps.EVENT_TYPE] ?? 'session'}`)
    const multiDayConfigLabel = flowConfig.selectionsTree[optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP] ? t(`${prefix}-${flowConfig.selectionsTree[optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP]}`) : basicLabel;
    return {
        EVENT_TYPE: t(`${prefix}-session`),
        APPOINTMENT_OR_AVAILABILITY_STEP: basicLabel,
        BASIC_INFO: (flowConfig.name === flowOptions.APPOINTMENT_WITH_AVAILABILITY || flowConfig.name === flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY) ? t(`${prefix}-appointment`) : basicLabel,
        REPEAT_CONFIG: basicLabel,
        AVAILABILITY_CONFIG: t(`${prefix}-appointment`),
        MULTI_DAY_CONFIG: flowConfig.name === flowOptions.SPACE_AVAILABILITY ? t('define-space-availability') : multiDayConfigLabel,
        SETTINGS: basicLabel,
        USER_AND_MEMBERSHIP_SELECTION: basicLabel,
        PAYMENT_METHOD_SELECTION: basicLabel,
        SUCCESS_PAGE: null,
        EDIT_MULTI_DAY_CONFIG: flowConfig.name === flowOptions.SPACE_AVAILABILITY ? t(`${prefix}-availability`) : multiDayConfigLabel,
        MEMBER_DETAILS: t(snakeToCamel(`ADD_NEW_${(flowConfig.name)}`)),
        STAFF_MEMBER_ROLE_SELECT: t('addNewStaffMember'),
        BLOCKED_TIME: t('blocked-time'),
        MESSAGE_TYPE: t('select-message-type'),
        MESSAGE_DETAILS: t('send-message'),
        EMAIL_AFTER_PURCHASE: t('email-after-purchase-title'),
        SPACE_CONFIGURATION: t('add-space'),
        PENALTY_SETTINGS: flowConfig.props.target ? t('edit-penalty-title') : t('penalty-settings-title'),
        PENALTY_EXTRA_PROPERTIES: t('penalty-extra-properties-title'),
        PENALTY_SUMMARY: t('summary'),
        TASK_DETAILS: flowConfig.props.target?.id ? t('editTaskHeader') : t('addNewTaskHeader'),
        TASK_TYPE: flowConfig.props.target ? t('editTaskType') : t('createTaskType'),
        SEND_CONVERT_LINK: t('send-convert-link-header'),
        CUSTOM_FIELD_TYPE_SELECTION: t(`custom-fields-settings-header-${isEdit ? 'edit' : 'create'}`),
        CUSTOM_FIELD_SETTINGS: t(`custom-fields-settings-header-${isEdit ? 'edit' : 'create'}`),
        SERIES_PAYMENT_OPTIONS: t(`${isEdit ? 'edit-' : ''}series-payment-options-step-header`),
        CHOOSE_HUG_PAYMENT_TYPE: t('bookHugPaymentTypeHeader'),
        PAYMENT: t('payment-step-header'),
        PERSONAL_DETAILS: t('bookNewMemberToHug'),
        SEND_ONE_TIME_MEMBERSHIP_LINK: t('send-membership-link-header'),
        REQUIRED_FIELDS_SETTINGS: t('required-fields-settings-header')
    }
}

export const stepSubHeader = () => {
    const flowConfig = store.getState().stepper
    const isEdit = flowConfig.editType !== null
    const type = flowConfig.selectionsTree[optionalSteps.EVENT_TYPE]
    const appointmentType = flowConfig.selectionsTree[optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP]
    return {
        EVENT_TYPE: t('event-type-step-subheader'),
        APPOINTMENT_OR_AVAILABILITY_STEP: t('one-on-one-subheader'),
        BASIC_INFO: t(`basic-info-step-subheader${isEdit ? '-edit' : ''}`, {type: t(((flowConfig.name === flowOptions.APPOINTMENT_WITH_AVAILABILITY || flowConfig.name === flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY) ? 'appointment' : type) + '-label')}),
        REPEAT_CONFIG: t(`repeat-config-step-subheader${isEdit ? '-edit' : ''}`),
        AVAILABILITY_CONFIG: t('availability-config-step-subheader'),
        MULTI_DAY_CONFIG: t(`multi-day-config-step-subheader-${appointmentType ?? type}`),
        SETTINGS: t('settings-step-subheader'),
        USER_AND_MEMBERSHIP_SELECTION: t('user-and-membership-step-subheader'),
        PAYMENT_METHOD_SELECTION: t('payment-method-step-subheader'),
        SUCCESS_PAGE: null,
        EDIT_MULTI_DAY_CONFIG: t(`edit-multi-day-config-step-subheader-${flowConfig.name === flowOptions.SPACE_AVAILABILITY ? 'space' : appointmentType ?? type}`),
        MEMBER_DETAILS: '',
        STAFF_MEMBER_ROLE_SELECT: t('addNewStaffMemberRolesInfo'),
        BLOCKED_TIME: '',
        MESSAGE_TYPE: t('select-message-type-subheader'),
        MESSAGE_DETAILS: '',
        EMAIL_AFTER_PURCHASE: t('email-after-purchase-info'),
        SPACE_CONFIGURATION: t('add-space-subheader'),
        PENALTY_SETTINGS: t('penalty-settings-subtitle'),
        PENALTY_EXTRA_PROPERTIES: t('penalty-extra-properties-subtitle'),
        PENALTY_SUMMARY: '',
        TASK_DETAILS: '',
        SEND_CONVERT_LINK: t('send-convert-link-subheader'),
        CUSTOM_FIELD_TYPE_SELECTION: t(`custom-fields-add-subheader`),
        CUSTOM_FIELD_SETTINGS: t(`custom-fields-settings-subheader-${isEdit ? 'edit' : 'create'}`),
        SERIES_PAYMENT_OPTIONS: t(`${isEdit ? 'edit-' : ''}series-payment-options-step-subheader`),
        CHOOSE_HUG_PAYMENT_TYPE: t('bookHugPaymentTypeSubheader'),
        SEND_ONE_TIME_MEMBERSHIP_LINK: t('send-membership-link-subheader'),
        PERSONAL_DETAILS: t('bookNewMemberToHugSubheader'),
        TASK_TYPE: '',
        REQUIRED_FIELDS_SETTINGS: t('required-fields-settings-subheader')
    }
}

export const shouldConsiderInProgressBar = ({
    EVENT_TYPE: false,
    APPOINTMENT_OR_AVAILABILITY_STEP: false,
    BASIC_INFO: true,
    REPEAT_CONFIG: true,
    AVAILABILITY_CONFIG: true,
    MULTI_DAY_CONFIG: true,
    SETTINGS: true,
    USER_AND_MEMBERSHIP_SELECTION: true,
    PAYMENT_METHOD_SELECTION: false,
    SUCCESS_PAGE: false,
    EDIT_MULTI_DAY_CONFIG: true,
    MEMBER_DETAILS: false,
    STAFF_MEMBER_ROLE_SELECT: false,
    BLOCKED_TIME: false,
    MESSAGE_TYPE: false,
    MESSAGE_DETAILS: false,
    EMAIL_AFTER_PURCHASE: false,
    SPACE_CONFIGURATION: false,
    CUSTOM_FIELD_TYPE_SELECTION: false,
    CUSTOM_FIELD_SETTINGS: false,
    SERIES_PAYMENT_OPTIONS: true,
    CHOOSE_HUG_PAYMENT_TYPE: true,
    PAYMENT: true,
    PERSONAL_DETAILS: true
})

export const showProgressBar = ({
    EVENT_TYPE: false,
    APPOINTMENT_OR_AVAILABILITY_STEP: false,
    BASIC_INFO: true,
    REPEAT_CONFIG: true,
    AVAILABILITY_CONFIG: true,
    MULTI_DAY_CONFIG: true,
    SETTINGS: true,
    USER_AND_MEMBERSHIP_SELECTION: true,
    PAYMENT_METHOD_SELECTION: true,
    SUCCESS_PAGE: false,
    EDIT_MULTI_DAY_CONFIG: true,
    MEMBER_DETAILS: false,
    STAFF_MEMBER_ROLE_SELECT: false,
    BLOCKED_TIME: false,
    MESSAGE_TYPE: false,
    MESSAGE_DETAILS: false,
    EMAIL_AFTER_PURCHASE: false,
    SPACE_CONFIGURATION: false,
    CUSTOM_FIELD_TYPE_SELECTION: false,
    CUSTOM_FIELD_SETTINGS: false,
    SERIES_PAYMENT_OPTIONS: true,
    CHOOSE_HUG_PAYMENT_TYPE: true,
    PAYMENT: true,
    PERSONAL_DETAILS: true
})

export const overrideCurrentValues = (stepName) => {
    const flowConfig = store.getState().stepper
    switch (stepName) {
        case optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP:
        case optionalSteps.EVENT_TYPE:
            return false
        case optionalSteps.SETTINGS:
            return flowConfig.flags.overrideValues ? false : true
        default:
            return true
    }
}

export const stepValidationSchema = () => ({
    EVENT_TYPE: yup.object().shape({}),
    BASIC_INFO: getBasicInfoValidationSchema(),
    REPEAT_CONFIG: getRepeatConfigValidationSchema(),
    AVAILABILITY_CONFIG: getAvailabilityConfigValidationSchema(),
    MULTI_DAY_CONFIG: getMultiDayConfigValidationSchema(),
    SETTINGS: yup.object().shape({
        transparent: yup.boolean().required(t('thisFieldIsMandatory')),
        disable_cancellation_time: yup.number().min(0).required(t('thisFieldIsMandatory')),
        enable_late_cancellation: yup.boolean().required(t('thisFieldIsMandatory')),
        cancel_limit: yup.number().nullable(),
        min_users: yup.number().integer().nullable(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        live_link: yup.string().nullable(),
        register_group_member: yup.boolean().required(t('thisFieldIsMandatory')),
        allow_mid_booking: yup.boolean().required(t('thisFieldIsMandatory')),
        block_registration_time: yup.number().when(['enable_registration_time'], (enable_registration_time, schema) => {
            return schema.test({
                test: blockRegistrationTime => blockRegistrationTime < enable_registration_time || blockRegistrationTime === 0 || enable_registration_time === 0,
                message: t('enableRegistrationSmallerOrEqualToBlock')
            })
        }),
        enable_registration_time: yup.number()
    }),
    USER_AND_MEMBERSHIP_SELECTION: yup.object().shape({
        scheduleUser: yup.object().shape({
            user_fk: yup.number().positive().required(t('thisFieldIsMandatory')),
            membership_user_fk: yup.number().positive()
                .when(['isLead', 'paymentObject', 'isNoMembership'], (isLead, paymentObject, isNoMembership) => (isLead === false && paymentObject === null && isNoMembership === false) ? yup.number().positive().required(t('thisFieldIsMandatory')) : yup.number().nullable()),
            isLead: yup.boolean().required(),
            paymentObject: yup.object().shape({
                cart: yup.object().shape({}).required(),
                method: yup.string().oneOf([ENTER_DEBT, FREE_PURCHASE])
            }).nullable(),
            force: yup.boolean().required(),
            sendInvitation: yup.boolean().required(),
            isNoMembership: yup.boolean().required()
        })
    }),
    PAYMENT_METHOD_SELECTION: yup.object().shape({}),
    SUCCESS_PAGE: yup.object().shape({}),
    EDIT_MULTI_DAY_CONFIG: getEditMultiDaysValidationSchema(),
    MEMBER_DETAILS: getMemberDetailsValidationSchema(),
    STAFF_MEMBER_ROLE_SELECT:  yup.object().shape({
        roles: yup.array(yup.string()).min(1, t('staff-roles-choose-at-least-one'))
    }),
    BLOCKED_TIME: yup.object().shape({
        coach_fk: yup.number().positive().nullable().typeError(t('thisFieldIsMandatory')),
        locations_box_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        place: yup.string().typeError(t('thisFieldIsMandatory')),
        spaces_id: yup.number().positive().nullable().typeError(t('thisFieldIsMandatory')),
    }).concat(getRepeatConfigValidationSchema()),
    MESSAGE_TYPE: yup.object().shape({
        type: yup.string().required(t('thisFieldIsMandatory')).oneOf([PUSH, SMS, EMAIL, WHATSAPP])
    }),
    MESSAGE_DETAILS: getMessageDetailsValidationSchema(),
    EMAIL_AFTER_PURCHASE: yup.object().shape({
        locations_box_id: yup.number().required(),
        membership_types: yup.array(yup.object().shape({
            id: yup.number().required()
        })).test('memberships', t('select-at-least-one-membership'), function (value) {
            return value?.filter?.(mt => mt.location_box_fk == this.parent.locations_box_id)?.length > 0
        }),
        users_boxes: yup.array(yup.object().shape({
            id: yup.number().required()
        })).min(1, t('select-at-least-one-staff-member')),
    }),
    SPACE_CONFIGURATION: getSpaceConfigValidationSchema(),
    PENALTY_SETTINGS: getPenaltiesSettingsValidationSchema(),
    PENALTY_EXTRA_PROPERTIES: getExtraPenaltiesPropertiesValidationSchema(),
    TASK_DETAILS: yup.object().shape({
        reminder_time: yup.string().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        user_fk: yup.number().integer().nullable().when('targeted', {
            is: value => value === targetAudienceTasks.SPECIFIC_USER,
            then: yup.number().min(1).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory'))
        }),
        task_type_fk: yup.number().integer().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        assigned_to: yup.number().integer().nullable(),
        description: yup.string(),
        locations_box_fk: yup.number().integer().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        done: yup.boolean().nullable(),
        targeted: yup.string(),
        check_in: yup.boolean(),
        access_control: yup.boolean(),
        is_personal: yup.number()
    }),
    TASK_TYPE: yup.object().shape({
        type: yup.string().required(),
        check_in: yup.boolean(),
        access_control: yup.boolean(),
        task_type_color: yup.string()

    }),
    SEND_CONVERT_LINK: getSendConvertLinkValidationSchema(),
    CUSTOM_FIELD_TYPE_SELECTION: yup.object().shape({customFieldType: yup.string().required().oneOf([SCHEDULE_TYPE, USER_TYPE])}),
    CUSTOM_FIELD_SETTINGS: yup.object().shape({
        customFieldName: yup.string()
            .required(t('thisFieldIsMandatory'))
            .typeError(t('thisFieldIsMandatory'))
            .min(2, t('customFieldNameMinMaxError'))
            .max(30, t('customFieldNameMinMaxError'))
            .test(
                'unique-name',
                t('nameAlreadyExistsError'),
                (value) =>  {
                    const target = store.getState().stepper.props?.target;
                    const originalField = target ? {id: target.custom_field_id, name: target.customFieldName} : null;
                    const customFieldsNames = store.getState().schedule.scheduleCustomFields?.filter(field => originalField ? originalField.id !== field.custom_field_id : field).map(field => field.custom_field_name);
                    return !customFieldsNames?.includes(value);
                }
            ),
        inputType: yup.string().when('customFieldType', {
            is: value => value === SCHEDULE_TYPE,
            then: yup.string().required(t('thisFieldIsMandatory')).oneOf([customFieldTypes.SELECT]),
            otherwise: yup.string().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory'))
        }),
        locations: yup.array().min(1, t('mustChooseAtLeastOneLocationError')).required(),
        flows: yup.array()
            .min(1, t('mustChooseAtLeastOneFlowError'))
            .test('at-least-one-checked', t('mustChooseAtLeastOneFlowError'), function(value) {
                return value?.some(flow => flow.checked === true);
            })
            .required(),
        props: yup.object().when('inputType', {
            is: customFieldTypes.SELECT,
            then: yup.object().shape({
                options: yup.array(yup.object().shape({
                    value: yup.string().required(t('thisFieldIsMandatory'))
                })).min(2, t('mustSetAtLeastTwoOptionsError')).required()
            }),
            otherAttribute: yup.mixed().test(
                'has-min-options',
                t('mustSetAtLeastTwoOptionsError'),
                function(value) {
                    const { options } = this.parent;
                    return options && options.length >= 2;
                }
            )
        })
    }),
    SERIES_PAYMENT_OPTIONS: yup.object().shape({
        paymentOptions: yup.array(yup.object().shape({
            type: yup.string().oneOf([HOK, TRIAL, IMMEDIATE]),
            show_in_app: yup.number().integer().min(0).max(3),
            max_installments: yup.number().integer().min(1).max(24).typeError(t('thisFieldIsMandatory')),
            price: yup.number().when('type', {
                is: type => type === HOK,
                then: yup.number().min(1, t('price-zero-invalid')).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                otherwise: yup.number().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory'))
            }),
            taxes: yup.array().of(yup.number().required(t('thisFieldIsMandatory'))).ensure()
        }))
    }),
    CHOOSE_HUG_PAYMENT_TYPE: yup.object().shape({
        cart: yup.array().of(yup.object().shape({
            id: yup.number().required(t('selectPaymentTypeError')).typeError(t('selectPaymentTypeError')),
        }))
    }),
    PAYMENT: yup.object().shape({
        payment_info: yup.object().required(),
    }),
    PERSONAL_DETAILS: getPersonalDetailsValidationSchema(),
    SEND_ONE_TIME_MEMBERSHIP_LINK: getSendMembershipLinkValidationSchema(),
    REQUIRED_FIELDS_SETTINGS: getRequiredFieldsSettingsValidationSchema()
})

export const stepInitialValues = ({eventDateTime, target, initProps}) => {
    const selectedTime = eventDateTime?.start ?? moment().startOf('hour').format(dbTimeFormat)
    const selectedDay = eventDateTime?.date ? moment(eventDateTime?.date, dbDateFormat).day() : moment().day()
    const flowName = store.getState().stepper.name
    return {
        EVENT_TYPE: {},
        BASIC_INFO: getBasicInfoInitialValues(target, initProps),
        REPEAT_CONFIG: getRepeatInitialValues(target, eventDateTime, selectedTime, selectedDay, flowName),
        AVAILABILITY_CONFIG: getAvailabilityConfigInitialValues(target, eventDateTime, selectedDay),
        MULTI_DAY_CONFIG: getMultiDayConfigInitialValues(eventDateTime, selectedDay, initProps),
        SETTINGS: getSettingsInitialValues(target),
        USER_AND_MEMBERSHIP_SELECTION: {
            scheduleUser: {
                user_fk: null,
                membership_user_fk: null,
                isLead: false,
                paymentObject: null,
                force: false,
                sendInvitation: true,
                isNoMembership: false
            }
        },
        PAYMENT_METHOD_SELECTION: {},
        SUCCESS_PAGE: {},
        EDIT_MULTI_DAY_CONFIG: getEditMultiDaysInitialValues(eventDateTime, target),
        MEMBER_DETAILS: getMemberDetailsInitialValues(target),
        STAFF_MEMBER_ROLE_SELECT: {
            roles: initProps?.roles ?? []
        },
        BLOCKED_TIME: {
            coach_fk: target?.coach_fk ?? null,
            locations_box_fk: target?.locations_box_fk ?? null,
            place: target?.place ?? '',
            spaces_id: target?.spaces_id ?? initProps?.spaces_id ?? null,
            ...getRepeatInitialValues(target, eventDateTime, selectedTime, selectedDay, flowName),
        },
        MESSAGE_TYPE: {
            type: target?.selectedType || ((target?.type === 'reports') && (target?.messageTypes.includes(PUSH) ? PUSH : SMS)) || PUSH,
            targetType: target?.type || null
        },
        MESSAGE_DETAILS: {},
        EMAIL_AFTER_PURCHASE: getEmailAfterPurchaseInitialValues(target),
        SPACE_CONFIGURATION: {
            locations_box_id: null,
            name: '',
            availabilityInfo: null
        },
        PENALTY_SETTINGS: getPenaltySettingsInitialValues(target),
        PENALTY_EXTRA_PROPERTIES: getPenaltyExtraPropertiesInitialValues(target),
        TASK_DETAILS: getTaskDetailsInitialValues(target),
        TASK_TYPE: getCreateTaskTypeInitialValues(target),
        SEND_CONVERT_LINK: getSendConvertLinkInitialValues(target),
        CUSTOM_FIELD_TYPE_SELECTION: {customFieldType: USER_TYPE},
        CUSTOM_FIELD_SETTINGS: getCustomFieldSettingsInitialValues(target),
        SERIES_PAYMENT_OPTIONS: getSeriesPaymentOptionsInitialValues(target),
        CHOOSE_HUG_PAYMENT_TYPE: { cart: null},
        PAYMENT: { payment_info: null },
        PERSONAL_DETAILS: getPersonalDetailsInitialValues(target),
        SEND_ONE_TIME_MEMBERSHIP_LINK: getSendMembershipLinkInitialValues(target),
        REQUIRED_FIELDS_SETTINGS: getRequiredFieldsSettingsInitialValues()
    }
}

export const onNextStep = ({
    EVENT_TYPE: (dispatch) => updateSelectionTree(dispatch),
    APPOINTMENT_OR_AVAILABILITY_STEP: (dispatch) => updateSelectionTree(dispatch),
    BASIC_INFO: () => console.log('onNext BASIC_INFO'),
    REPEAT_CONFIG: () => console.log('onNext REPEAT_CONFIG'),
    AVAILABILITY_CONFIG: () => console.log('onNext AVAILABILITY_CONFIG'),
    MULTI_DAY_CONFIG: () => console.log('onNext MULTI_DAY_CONFIG'),
    SETTINGS: () => console.log('onNext SETTINGS'),
    USER_AND_MEMBERSHIP_SELECTION: () => console.log('onNext USER_AND_MEMBERSHIP_SELECTION'),
    PAYMENT_METHOD_SELECTION: () => console.log('onNext PAYMENT_METHOD_SELECTION'),
    SUCCESS_PAGE: null,
    EDIT_MULTI_DAY_CONFIG: () => console.log('onNext EDIT_MULTI_DAY_CONFIG'),
    MEMBER_DETAILS: (dispatch) => memberDetailsOnNext(dispatch),
    SPACE_CONFIGURATION: () => null,
    CUSTOM_FIELD_TYPE_SELECTION: () => null,
    CUSTOM_FIELD_SETTINGS: () => null,
    SERIES_PAYMENT_OPTIONS: () => null,
    CHOOSE_HUG_PAYMENT_TYPE: () => console.log('onNext CHOOSE_HUG_PAYMENT_TYPE'),
    PERSONAL_DETAILS: () => console.log('onNext PERSONAL_DETAILS')
})

const renderEventTypeSelectionStep = (props) => {
    return <EventTypeStep initSelection={props?.initSelection || null}/>
}

const renderAppointmentOrAvailabilityStep = (props) => {
    return <OneOnOneType {...props}/>
}

const renderBasicInfoStep = (props) => {
    const flowName = store.getState().stepper?.name
    const editType = store.getState().stepper?.editType
    const fromAvailability = (flowName === flowOptions.APPOINTMENT_WITH_AVAILABILITY || flowName === flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY)
    let additional = {}

    additional.showMaxUsersTooltip = true;
    additional.showSpaces = flowName !== flowOptions.APPOINTMENT_WITH_AVAILABILITY && (props?.hasPermission('spaces') && !props?.hasPermission('spacesLock'));
    additional.showCoach = true;

    if(flowName === flowOptions.APPOINTMENT_BASIC && editType) {
        additional.disableLocation = true
        additional.disableService = true
    }
    if(fromAvailability && props.values.locations_box_fk) {
        additional.disableLocation = true
    }
    if(flowName === flowOptions.WORKSHOP_FLOW && editType) {
        additional.disableService = true
        additional.disableLocation = editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE
    }
    if(flowName === flowOptions.GROUP_CLASS_FLOW && editType) {
        additional.serviceChangeAlert = true
    }
    if(flowName === flowOptions.APPOINTMENT_WITH_AVAILABILITY && editType) {
        additional.disableLocation = true
        additional.disableService = true
        additional.disableCoach = true
        additional.showSpaces = false
        additional.showCoach = true
    }
    if(flowName === flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY && !editType) {
        additional.showCoach = false
        additional.disableSpace = true
        additional.disableLocation = true
    }
    if(flowName === flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY && editType) {
        additional.disableLocation = true
        additional.disableService = true
        additional.disableSpace = true
        additional.showSpaces = true
        additional.showCoach = false;
    }
    if(flowName === flowOptions.WORKSHOP_FLOW || flowName === flowOptions.HUGIM_FLOW) {
        additional.showMaxUsersTooltip = false
    }
    if(editType && flowName === flowOptions.HUGIM_FLOW) {
        additional.disableLocation = true
    }
    return <BasicInformation showLiveLinkSetting={flowName === flowOptions.APPOINTMENT_BASIC || fromAvailability} fromAvailability={fromAvailability} {...additional} {...props}/>
}

const renderRepeatConfigStep = (props) => {
    const flowName = store.getState().stepper?.name
    return <RepeatConfiguration allowSeries={flowName !== flowOptions.APPOINTMENT_BASIC} {...props}/>
}

export const renderAvailabilityConfigStep = (props) => {
    const editType = store.getState().stepper?.editType
    let additional = {
        disableDate: editType === null
    }
    return <AvailabilitySlotSelection {...additional} {...props}/>
}

const renderSettingsStep = (props) => {
    const flowName = store.getState().stepper.name
    const editType = store.getState().stepper?.editType

    let additional = {}
    switch (flowName) {
        case flowOptions.GROUP_CLASS_FLOW:
            additional.showTransparentSettings = true;
            additional.showCancellationSettings = true;
            additional.showRegistrationRestrictionsSettings = true;
            additional.showRegistrationBlockSettings = true;
            additional.showMinimumParticipantsSettings = true;
            additional.showGroupRegisterSettings = false;
            additional.showOnlineSessionSettings = true;
            additional.showSeriesNameSettings = (props?.values.repeatConfig?.repeat && (props?.values.repeatConfig.type === 'forever') && props?.values.repeatConfig.days.length === 1) || (editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE && props?.target?.series_fk);
            additional.showBookForTrialSettings = props?.values.repeatConfig?.repeat || (editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE && props?.target?.series_fk)
            break;
        case flowOptions.WORKSHOP_FLOW:
            additional.showTransparentSettings = false;
            additional.showCancellationSettings = false;
            additional.showRegistrationRestrictionsSettings = false;
            additional.showRegistrationBlockSettings = false;
            additional.showMinimumParticipantsSettings = false;
            additional.showGroupRegisterSettings = (props?.hasPermission('allowRegisterGroupMember') && editType !== SINGLE_SESSION_EDIT_TYPE) ?? false;
            additional.showOnlineSessionSettings = true;
            additional.showSeriesNameSettings = editType !== SINGLE_SESSION_EDIT_TYPE;
            break;
        case flowOptions.HUGIM_FLOW:
            additional.showTransparentSettings = false;
            additional.showCancellationSettings = false;
            additional.showRegistrationRestrictionsSettings = false;
            additional.showRegistrationBlockSettings = false;
            additional.showMinimumParticipantsSettings = false;
            additional.showOnlineSessionSettings = true;
            additional.showSeriesNameSettings = editType !== SINGLE_SESSION_EDIT_TYPE;
            additional.showGroupRegisterSettings = (props?.hasPermission('allowRegisterGroupMember') && editType !== SINGLE_SESSION_EDIT_TYPE) ?? false;
            additional.defaultOpenedSection = 'seriesNameSetting';
            additional.showMidBookingSettings = editType !== SINGLE_SESSION_EDIT_TYPE;
            break;
    }
    return <SessionSettings displayProps={additional} {...props}/>
}

const renderMultiDayConfigStep = (props) => {
    const flowName = store.getState().stepper.name
    let additional = {}
    additional.showCoachSelect = flowName !== flowOptions.SPACE_AVAILABILITY
    additional.showSpacesSelect = flowName === flowOptions.SPACE_AVAILABILITY
    additional.servicesTypeOverride = flowName === flowOptions.SPACE_AVAILABILITY ? APPOINTMENT : null
    additional.showLocationSelect = flowName === flowOptions.AVAILABILITY
    additional.showUnlimitedOption = flowName === flowOptions.HUGIM_FLOW && props?.hasPermission('recurringPaymentActions')
    additional.showShortenName = flowName === flowOptions.HUGIM_FLOW
    return <MultiDayConfiguration {...additional} {...props}/>
}

export const renderEditMultiDayConfig = (props) => {
    let additional = {}
    const flowName = store.getState().stepper.name
    const editType = store.getState().stepper?.editType
    let hasSchedules;
    switch (flowName) {
        case flowOptions.WORKSHOP_FLOW:
            additional.disableDate = editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE
            additional.disableDays = editType === SINGLE_SESSION_EDIT_TYPE
            additional.allowDaySwitch  = editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE
            additional.showLength = true
            additional.hideBlockRegistrationTime = true
            break;
        case flowOptions.HUGIM_FLOW:
            additional.hideBlockRegistrationTime = true
            additional.showOnlyTimeSelectors = editType === SINGLE_SESSION_EDIT_TYPE
            additional.singleDay = editType === SINGLE_SESSION_EDIT_TYPE
            additional.allowDaySwitch = editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE
            additional.allowEditDateRange = editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE
            additional.showDaysEditInfoText = editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE
            break;
        case flowOptions.AVAILABILITY:
            hasSchedules = props.target?.hasSchedules;
            additional.disableDate = true
            additional.disableDays = false
            additional.disableLocation = editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE ? hasSchedules : true // if edit all then enable only if there are no connected schedules. else (aka single edit), disable always
            additional.disableCoach = hasSchedules
            additional.allowDaySwitch = false
            additional.allowTimeExpandOnly = hasSchedules
            additional.singleDay = true
            additional.showServices = true
            additional.showLocationSelect = true
            additional.showCoachSelect = true
            additional.showSpaceSelect = false
            break;
        case flowOptions.SPACE_AVAILABILITY:
            hasSchedules = props.target?.hasSchedules;
            additional.disableDate = true
            additional.disableDays = false
            additional.disableLocation = editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE ? hasSchedules : true // if edit all then enable only if there are no connected schedules. else (aka single edit), disable always
            additional.disableSpaces = hasSchedules
            additional.allowDaySwitch = false
            additional.allowTimeExpandOnly = hasSchedules
            additional.singleDay = true
            additional.showServices = true
            additional.showLocationSelect = true
            additional.showCoachSelect = false
            additional.showSpaceSelect = true
            additional.servicesTypeOverride = APPOINTMENT
            additional.disableSpace = hasSchedules
            break;
    }
    return <EditMultiDayConfig {...additional} {...props}/>
}

const renderUserAndMembershipSelection = (props) => {
    const flowName = store.getState().stepper.name
    const selectionsTree = store.getState().stepper.selectionsTree
    const services = store.getState().schedule.eventTypes.data
    let oneTimeMembership = null;
    let additional = {}
    const hideOneTimePurchase = props?.values.repeatConfig.repeat === true

    if(services && selectionsTree && selectionsTree[optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP]) {
        oneTimeMembership = services[selectionsTree[optionalSteps.APPOINTMENT_OR_AVAILABILITY_STEP]].find(category => category.id === props.values?.box_category_fk)
        oneTimeMembership = oneTimeMembership?.membership_types.find(membership => membership.location_box_fk === props.values?.locations_box_fk)
    } else if(services && flowName === flowOptions.APPOINTMENT_WITH_AVAILABILITY) {
        oneTimeMembership = services['appointment'].find(category => category.id === props.values?.box_category_fk)
        oneTimeMembership = oneTimeMembership?.membership_types.find(membership => membership.location_box_fk === props.values?.locations_box_fk)
    }
    additional.oneTimeMembership = oneTimeMembership
    additional.showOneTimePurchase = !!(!hideOneTimePurchase && oneTimeMembership)
    additional.showProTip = isBoxInTrial()

    return <UserAndMembershipSelection {...additional} {...props}/>
}

const renderSpaceConfigStep = (props) => {
    return <SpaceConfiguration {...props}/>
}

const renderPaymentMethodSelection = (props) => {
    return <PaymentMethod {...props}/>
}

const renderMemberDetails= (props) => {
    return <MemberDetails {...props}/>
}

const renderStaffMemberRoleSelect= (props) => {
    return <StaffMemberRoleSelection {...props}/>
}

const renderBlockedTime= (props) => {
    return <BlockedTime {...props}/>
}

const renderMessageTypeSelect= (props) => {
    return <MessageType {...(props || {})}/>
}

const renderMessageDetails= (props) => {
    return <MessageDetails {...(props || {})}/>
}

const renderEmailAfterPurchase = (props) => {
    return <EmailAfterPurchase {...props}/>
}

const renderPenaltySettingsConfigStep = (props) => {
    return <PenaltySettings {...props}/>
}

const renderPenaltySummaryStep = (props) => {
    return <PenaltySummary {...props}/>
}

const renderPenaltyExtraPropertiesConfigStep = (props) => {
    return <PenaltyExtraProperties {...props}/>
}

const renderTaskDetailsStep = (props) => {
    return <TaskDetails {...props}/>
}

const renderTaskTypeStep = (props) => {
    return <CreateTaskType {...props}/>
}

const renderSendConvertLinkStep = (props) => {
    return <SendConvertLink {...props}/>
}

const renderCustomFieldTypeSelection = (props) => {
    return <CustomFieldSelection {...props} />;
}

const renderCustomFieldSettings = (props) => {
    const target = props.target;
    const addons = {isTypeDisabled: !!target, currentFlows: target?.custom_field_flow}
    return <CustomFieldSettings {...props} {...addons}/>;
}

const renderSuccessStep = (props) => {
    const addonProps = getSuccessProps({...props})
    return <SuccessStep {...addonProps} {...props}/>
}

const renderSeriesPaymentOptionsStep = (props) => {
    const editType = store.getState().stepper?.editType
    let additional = {}
    additional.showEditMessage = editType !== null
    additional.isEdit = editType !== null
    additional.showTrialOption = props?.hasPermission('bookForTrialHugim') && editType !== SINGLE_SESSION_EDIT_TYPE
    return <SeriesPaymentOptions {...additional} {...props}/>
}

const renderChoosePaymentOptionsStep = (props) => {
    let additional = {}
    additional.paymentOptions = props.target.series.membership_types_all
    additional.startDate = props.target.date;
    return <HugPaymentsOptions {...additional} {...props}/>
}

const renderPayment = (props) => {
    const flowName = store.getState().stepper.name
    const membership = store.getState().stepper.selectionsTree[optionalSteps.CHOOSE_HUG_PAYMENT_TYPE]
    let additional = {}
    if(flowName === flowOptions.BOOK_TO_HUG_FLOW && membership) {
        additional.cartOverride = [membership]
    }
    return <Payment {...additional} {...props}/>
}

const renderPersonalDetails = (props) => {
    return <PersonalDetails {...props}/>

}

const renderSendOneTimeMembershipLink = (props) => {
    return <SendMembershipLink {...props} />
}

const renderRequiredFieldsSettings = (props) => {
    return <RequiredFieldsSettings {...props}/>
}

const getMultiDayConfigInitialValues = (eventDateTime, selectedDay, initProps) => {
    const flowName = store.getState().stepper.name
    const day = moment(eventDateTime?.date).day()
    switch (flowName) {
        case flowOptions.WORKSHOP_FLOW:
            return {
                start_date: eventDateTime?.date ?? moment().format(dbDateFormat),
                end_date: eventDateTime?.date ? moment(eventDateTime?.date, dbDateFormat).add(1, 'month').format(dbDateFormat) : moment().add(1, 'month').format(dbDateFormat),
                multiDayConfig: {
                    days: selectedDay ? [{day: selectedDay, start_time: eventDateTime?.start ?? '09:00:00', end_time: '17:00:00'}] : [{day: moment().day(), start_time: eventDateTime?.start ?? '09:00:00', end_time: '17:00:00'}],
                    seriesName: ''
                },
            }
        case flowOptions.HUGIM_FLOW:
            return {
                isUnlimited: false,
                start_date: eventDateTime?.date ?? moment().format(dbDateFormat),
                end_date: eventDateTime?.date ? moment(eventDateTime?.date, dbDateFormat).add(1, 'month').format(dbDateFormat) : moment().add(1, 'month').format(dbDateFormat),
                multiDayConfig: {
                    days: selectedDay ? [{day: selectedDay, start_time: eventDateTime?.start ?? '09:00:00', end_time: '17:00:00'}] : [{day: moment().day(), start_time: eventDateTime?.start ?? '09:00:00', end_time: '17:00:00'}],
                    seriesName: ''
                },
            }
        case flowOptions.AVAILABILITY:
            return {
                date: eventDateTime?.date ?? moment().format(dbDateFormat),
                location_fk: null,
                coach_ub_id: initProps?.coach_ub_id ?? null,
                repeat: true,
                slot_length: 15,
                break_length: 0,
                block_registration_time: 0,
                multiDayConfig: [{day: day, setting: [{start_time: '09:00:00', end_time: '17:00:00', limitations: []}]}]
            }
        case flowOptions.SPACE_AVAILABILITY:
            return {
                date: eventDateTime?.date ?? moment().format(dbDateFormat),
                location_fk: null,
                spaces_id: initProps?.spaces_id ?? null,
                repeat: true,
                slot_length: 15,
                break_length: 0,
                block_registration_time: 0,
                multiDayConfig: [{day: day, setting: [{start_time: '09:00:00', end_time: '17:00:00', limitations: []}]}]
            }
        default:
            return {}
    }
}

const getMultiDayConfigValidationSchema = () => {
    const flowName = store.getState().stepper?.name
    switch (flowName) {
        case flowOptions.WORKSHOP_FLOW:
            return getBasicWorkshopValidationSchema().shape({
                end_date: yup.string().required(t('thisFieldIsMandatory')).when(['start_date'], (start_date, schema) => {
                    const start = moment(start_date, dbDateFormat)
                    return schema.test({
                        test: end => moment(end, dbDateFormat).isAfter(start),
                        message: t('shiftTimeError')
                    })
                }).test('date-format', 'invalid date', function(value){
                    return moment(value, dbDateFormat).format(dbDateFormat) === value
                }),
            })
        case flowOptions.HUGIM_FLOW:
            return getBasicWorkshopValidationSchema().shape({
                isUnlimited: yup.boolean(),
                end_date: yup.string().when("isUnlimited", {
                    is: true,
                    then: yup.string().nullable(true).test('is-null', 'end_date must be null', value => value === null), //must be null when isUnlimited=true
                    otherwise: yup.string().required(t('thisFieldIsMandatory')).when(['start_date'], (start_date, schema) => {
                        const start = moment(start_date, dbDateFormat)
                        return schema.test({
                            test: end => end && moment(end, dbDateFormat).isAfter(start),
                            message: t('shiftTimeError')
                        })
                    }).test('date-format', 'invalid date', function(value){
                        return value && (moment(value, dbDateFormat).format(dbDateFormat) === value)
                    }),
                })
            })
        case flowOptions.AVAILABILITY:
            return getAvailabilityBasicValidationSchema().shape({
                location_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                coach_ub_id: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
            })
        case flowOptions.SPACE_AVAILABILITY:
            return getAvailabilityBasicValidationSchema().shape({
                location_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                spaces_id: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
            })
        default:
            return yup.object().shape({})
    }
}

const getBasicInfoInitialValues = (target, initProps) => {
    const flowName = store.getState().stepper?.name
    const editType = store.getState().stepper?.editType
    if(editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE && target.series) {
        target = target.series
    }
    let values = {
        box_category_fk: target?.box_category_fk ?? null,
        coach_fk: target?.coach_fk ?? target?.coach?.user_fk ?? null,
        locations_box_fk: target?.locations_box_fk ?? target?.location_fk ?? null,
        spaces_id: target?.spaces_id ?? initProps?.spaces_id ?? null,
        customFields: customFieldInit(target, editType)
    }
    switch (flowName) {
        case flowOptions.APPOINTMENT_BASIC:
            values.live_link = target?.live_link ?? null
            break;
        case flowOptions.APPOINTMENT_WITH_AVAILABILITY:
        case flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY:
            values.live_link = null
            values.availability_id = target?.availability_id ?? target?.id
            values.customFields = customFieldInit(target, editType, flowOptions.APPOINTMENT_BASIC)
            break;
        default:
            values.max_users = target?.max_users ?? null
            values.second_coach_fk = target?.second_coach_fk ?? null
            break;
    }
    return values;
}

const customFieldInit = (target, editType, flowNameOverride = null) => {
    let temp = [];
    const flowName = store.getState().stepper?.name;
    const isUserFlowType = flowName === flowOptions.BOOK_TO_HUG_FLOW;
    const userCustomFields = store.getState().schedule.userCustomFields
    const scheduleCustomFields = store.getState().schedule.scheduleCustomFields
    const customFieldByFlowType = isUserFlowType ? userCustomFields : scheduleCustomFields;
    const customFieldByFlowName = customFieldByFlowType?.filter(customField => customField.custom_field_flow.some(flowObj => flowObj.flow.name === (flowNameOverride ?? flowName)))
    const currentFields = target?.custom_field_value?.length ? target?.custom_field_value : target?.series?.custom_field_value;

    const byFlowNameFormatted = customFieldByFlowName?.map(field => ({
        ...field,
        custom_field_id: field.id,
        customFieldType: flowName ===  flowOptions.BOOK_TO_HUG_FLOW ? 'User' : 'Schedule', //not sure why its capitalized, not changing because im afraid to break something.
        props: field.props,
        editType: editType,
        date: target?.date,
        modelId: target?.id,
        value: field?.value,
    }));

    if(isUserFlowType) {
        //TODO (might be changed in the future)
        // in case its user type, otherwise continue to the array merge (happens just for schedule, might be changed later)
        return byFlowNameFormatted;
    }

    const currentFieldsFormatted = currentFields?.map(field => ({
            id: field.custom_field.id,
            custom_field_value_id: field.id,
            custom_field_id: field.custom_field.id,
            custom_field_name: field.custom_field.name,
            custom_field_type: field.custom_field.type,
            props: field.custom_field.props,
            customFieldType: 'Schedule', //TODO get it from somewhere
            editType: editType,
            date: target.date,
            modelId: target.id,
            value: field.value
    }));

    temp = [...currentFieldsFormatted ?? [], ...byFlowNameFormatted ?? []];
    return uniqBy(temp, 'id');
}

const getCustomFieldValueValidationSchema = () => {
    return yup.array(yup.object().shape({
        value: yup.string().nullable()
            .when('props', {
                is: value => value.isRequired,
                then: schema => schema.required(t('thisFieldIsMandatory'))
            })
            .when('custom_field_type', {
                is: value => value === 'phone',
                then: schema => schema.matches(/^(\+?\d{5,15})$/, t('non-numeric-chars')).min(5, t('phoneNumberIsTooShort', {min: 5})),
            }),
    }));
}

const getCustomFieldSettingsInitialValues = (target) => {
    const box = store.getState().box.box;
    const locationOptions =
        target?.locations_box ?
            (Array.isArray(target.locations_box) ?
                target.locations_box.map(location => ({ id: location.id, value: location.id, label: location.location })) :
                []) :
            (Array.isArray(box?.locations_box) ?
                box.locations_box.map(location => ({ id: location.id, value: location.id, label: location.location })) :
                []);
    const selectionsTree = store.getState().stepper.selectionsTree;
    const customFieldType = target?.flow_type ?? selectionsTree[optionalSteps.CUSTOM_FIELD_TYPE_SELECTION] ?? null;

    const data = {
        custom_field_id: target?.id ?? null,
        customFieldName: target?.custom_field_name ?? '',
        locations: locationOptions,
        inputType: target?.custom_field_type ?? customFieldTypes.SELECT,
        props: target?.props ?? {isRequired: true},
        flows: [],
    }
    if(customFieldType) { //in case of user funnel is selected
        return {...data, customFieldType }
    }

    return data;
}

const getBasicInfoValidationSchema = (target) => {
    const flowName = store.getState().stepper?.name
    switch (flowName) {
        case flowOptions.APPOINTMENT_BASIC:
            return yup.object().shape({
                box_category_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                coach_fk: yup.number().positive().nullable().typeError(t('thisFieldIsMandatory')),
                spaces_id: yup.number().positive().nullable().typeError(t('thisFieldIsMandatory')),
                locations_box_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                live_link: yup.string().nullable(),
            })
        case flowOptions.APPOINTMENT_WITH_AVAILABILITY:
            return yup.object().shape({
                box_category_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                coach_fk: yup.number().positive().required(),
                locations_box_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                live_link: yup.string().nullable(),
                availability_id: yup.number().positive().required()
            })
        case flowOptions.APPOINTMENT_WITH_SPACE_AVAILABILITY:
            return yup.object().shape({
                box_category_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                locations_box_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                spaces_id: yup.number().positive().nullable().typeError(t('thisFieldIsMandatory')),
                live_link: yup.string().nullable(),
                availability_id: yup.number().positive().required()
            })
        default:
            return yup.object().shape({
                box_category_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                coach_fk: yup.number().positive().nullable(),
                second_coach_fk: yup.number().positive().nullable(),
                locations_box_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                spaces_id: yup.number().positive().nullable().typeError(t('thisFieldIsMandatory')),
                max_users: yup.number().min(0).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
                customFields: getCustomFieldValueValidationSchema(target)
            })
    }
}

const updateSelectionTree = (dispatch) => {
    const tempSelection = store.getState().stepper.tempSelection
    if(tempSelection) dispatch(addToSelectionsTree(tempSelection))
}

const memberDetailsOnNext = (dispatch) => {
    const tempSelection = store.getState().stepper.tempSelection
    if (tempSelection) {
        dispatch(isEmailAvailable(tempSelection))
    }
}

const getRepeatConfigValidationSchema = () => {
    const isEdit = store.getState().stepper.editType
    return yup.object().shape({
        date: yup.string().required(t('thisFieldIsMandatory')).test('date-format', 'invalid date', function(value){
            return moment(value, dbDateFormat).format(dbDateFormat) === value
        }),
        time: yup.string().required(t('thisFieldIsMandatory')).test('time-format', 'invalid time', function(value){
            return moment(value, dbTimeFormat).format(dbTimeFormat) === value
        }),
        end_time: yup.string().required(t('thisFieldIsMandatory')).when(['time'], (time, schema) => {
            const start = moment(time, dbTimeFormat)
            return schema.test({
                test: end => moment(end, dbTimeFormat).isAfter(start),
                message: t('shiftTimeError')
            })
        }).test('time-format', 'invalid time', function(value){
            return moment(value, dbTimeFormat).format(dbTimeFormat) === value
        }),
        repeatConfig: yup.object().when([], {
            is: () => isEdit === null,
            then: yup.object().shape({
                repeat: yup.boolean().required(t('thisFieldIsMandatory')),
                type: yup.string().nullable().when(['repeat'], (repeat_val, schema) => {
                    return schema.test({
                        test: type => repeat_val ? ['forever', 'until', 'times'].includes(type) : true,
                        message: 'invalid type'
                    })
                }),
                until: yup.string().nullable().when("type", {
                    is: 'times',
                    then: yup.string().required(t('thisFieldIsMandatory')).test('date-format', 'invalid date', function (value) {
                        return moment(value, dbDateFormat).format(dbDateFormat) === value
                    })
                }),
                times: yup.number().nullable().when("type", {
                    is: 'times',
                    then: yup.number().positive().required(t('thisFieldIsMandatory')).min(1).max(104)
                }),
                days: yup.array().of(yup.number().min(0).max(6)).min(1, t('one-day-minimum')),
                seriesName: yup.string().nullable()
            }),
            otherwise: yup.object().nullable().notRequired()
        })
    })
}

const getAvailabilityConfigValidationSchema = () => {
    const isEdit = store.getState().stepper.editType
    if(isEdit) {
        return yup.object().shape({
            date: yup.string().required(t('thisFieldIsMandatory')).test('date-format', 'invalid date', function (value) {
                return moment(value, dbDateFormat).format(dbDateFormat) === value
            }),
            time: yup.string().required(t('thisFieldIsMandatory')).test('time-format', 'invalid time', function (value) {
                return moment(value, dbTimeFormat).format(dbTimeFormat) === value
            }),
            end_time: yup.string().required(t('thisFieldIsMandatory')).when(['time'], (time, schema) => {
                const start = moment(time, dbTimeFormat)
                return schema.test({
                    test: end => moment(end, dbTimeFormat).isAfter(start),
                    message: t('shiftTimeError')
                })
            }).test('time-format', 'invalid time', function (value) {
                return moment(value, dbTimeFormat).format(dbTimeFormat) === value
            })
        })
    } else return getRepeatConfigValidationSchema()
}

const getMemberDetailsValidationSchema = (target) => {
    const type = store.getState().stepper.name?.toLowerCase()
    const boxSettings = store.getState().box.box.settings?.[USER_FIELDS]
    const leadFlow = type === flowOptions.LEAD.toLowerCase();
    return yup.object().shape({
        first_name: yup.string().required(t('thisFieldIsMandatory')),
        last_name: conditionalUserDetailsSchema(yup.string(), boxSettings, 'last_name', type),
        email: conditionalUserDetailsSchema(yup.string().email(t('emailIsInvalid')).test('email-domains', t('invalidEmailDomain'), function (value) {
            return !validateAfterEmailSymbol(value);
        }), boxSettings, 'email', type),
        phone: conditionalUserDetailsSchema(yup.string(), boxSettings, 'phone', type),
        personal_id: yup.string(),
        birthday: conditionalUserDetailsSchema(yup.string().nullable(true), boxSettings, 'birthday', type),
        gender: conditionalUserDetailsSchema(yup.string(), boxSettings, 'gender', type),
        locations_box_fk: yup.number().required(t('thisFieldIsMandatory')),
        source_fk: conditionalUserDetailsSchema(yup.number().nullable(true), boxSettings, 'source_fk', type),
        status_fk: leadFlow ? yup.number().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')) : yup.number().nullable(true),
        users_boxes_owner_id: conditionalUserDetailsSchema(yup.number().nullable(true), boxSettings, 'users_boxes_owner_id', type),
        city: conditionalUserDetailsSchema(yup.string(), boxSettings, 'city', type),
        address: conditionalUserDetailsSchema(yup.string(), boxSettings, 'address', type),
        customFields: getCustomFieldValueValidationSchema(target)
    })
}

const conditionalUserDetailsSchema = (schema, boxSettings, field, userType) => {
    if (boxSettings?.[field]?.[`${userType}Required`]) {
        return schema.test('required', t('thisFieldIsMandatory') , value => !!value)
    }
    return schema
}

const getMessageDetailsValidationSchema = () => {
    return yup.object().shape({
        class_name: yup.string().oneOf(messageAudience).required(t('thisFieldIsMandatory')),
        users: yup.array(yup.object().shape({
            user_fk: yup.number().positive(),
        })).when('class_name', {
            is: value => value === 'ReportMessage' || value === 'PrivateMessage',
            then: yup.array().test('required', t('thisFieldIsMandatory') ,(value) => value?.filter(u => u.isSelected)?.length)
        }).when(['type', 'class_name'], {
            is: (type, class_name) => type === EMAIL && class_name === 'PrivateMessage',
            then: yup.array(yup.object().shape({
                email: yup.string().required(t('user-must-have-an-email')).typeError(t('user-must-have-an-email')),
            }))
        }).when(['type', 'class_name'], {
            is: (type, class_name) => (type === SMS || type === WHATSAPP) && class_name === 'PrivateMessage',
            then: yup.array(yup.object().shape({
                phone: yup.string().required(t('user-must-have-a-phone-number')).typeError(t('user-must-have-a-phone-number'))
            }))
        }),
        locations_box_fk: yup.number(),
        senderID: yup.string().nullable().when('type', {
            is: EMAIL,
            then: yup.string().nullable().email(t('sender-email-is-invalid')).required(t('thisFieldIsMandatory'))
            .test('sender' ,t('sender-email-is-invalid'), (value) => !validateAfterEmailSymbol(value))
        }).when('type', {
            is: SMS,
            then: yup.string().nullable().required(t('thisFieldIsMandatory'))
            .test('sender', t('sender-is-invalid'), (value) => /^[a-zA-Z0-9]{1,11}$/g.test(value))
            .test('verified', t('sender-must-be-verified'), function (value) {
                return (this.parent.verifiedSenderID && this.parent.verifiedSenderID === value) || !/^\d+$/.test(this.parent.senderID)
            })
        }),
        verifiedSenderID: yup.string().nullable(),
        messageContent: yup.string().required(t('thisFieldIsMandatory')).test('no-emojis', t('no-emojis-are-allowed'), isStringWithoutEmoji),
        message_array: yup.array(yup.object().shape({
            text: yup.string(),
            key: yup.string(),
            keyDB: yup.string(),
        })),
        subject: yup.string().when('type', {
            is: EMAIL,
            then: yup.string().required(t('thisFieldIsMandatory'))
        }).test('no-emojis', t('no-emojis-are-allowed'), isStringWithoutEmoji)
    })
}

const getPersonalDetailsValidationSchema = (target) => {
    const boxSettings = store.getState().box.box.settings?.[USER_FIELDS]
    return yup.object().shape({
        first_name: yup.string().required(t('thisFieldIsMandatory')),
        last_name: yup.string().required(t('thisFieldIsMandatory')),
        email: conditionalUserDetailsSchema(yup.string().nullable(true).email(t('emailIsInvalid')).test('email-domains', t('invalidEmailDomain'), function (value) {
            return !validateAfterEmailSymbol(value);
        }), boxSettings, 'email', 'member'),
        emailValidated: yup.boolean().when('email', {
            is: value => value !== '' && value !== null,
            then: yup.boolean().required().oneOf([true]),
            otherwise: yup.boolean().nullable(true)
        }),
        phone: conditionalUserDetailsSchema(yup.string().nullable(true).matches(/^(\+?\d{5,15})$/, t('invalid-phone'))
        .min(5, t('min-chars', {min: 5})), boxSettings, 'phone', 'member'),
        personal_id: conditionalUserDetailsSchema(yup.string().nullable(true).matches(/^[0-9]*$/, t('numbers-only')).test('length-test', t('personal-id-length-error'), val => {
            let id = String(val).trim();
            if(val === null || val === '') return true
            return !(id.length > 9 || id.length < 5);
        }), boxSettings, 'personal_id', 'member'),
        personalIdValidated: yup.boolean().when('personal_id', {
            is: value => value !== '' && value !== null,
            then: yup.boolean().required().oneOf([true]),
            otherwise: yup.boolean().nullable(true)
        }),
        birthday: conditionalUserDetailsSchema(yup.string().nullable(true), boxSettings, 'birthday', 'member'),
        gender: conditionalUserDetailsSchema(yup.string().nullable(true), boxSettings, 'gender', 'member'),
        locations_box_fk: yup.number().required(t('thisFieldIsMandatory')),
        rfid: yup.number().nullable(true),
        invoice_name: yup.string().nullable(true).max(30, t('max-chars', {max: 30})),
        allow_mailing_list: yup.string().oneOf(['yes','no']),
        allow_sms: yup.string().oneOf(['yes','no']),
        customFields: getCustomFieldValueValidationSchema(target)
    })
}

const getSendMembershipLinkValidationSchema = () => {
    return yup.object().shape({
        user_fk: yup.number().required(t('thisFieldIsMandatory')).test('user_has_phone_email', t('user-must-have-a-phone-number-and-email'), function (value) {
            return (this.parent.send_whatsapp || this.parent.send_sms) && this.parent.send_email ? this.parent.phone && this.parent.email : true
        }).test('user_has_phone', t('user-must-have-a-phone-number'), function (value) {
            return (this.parent.send_whatsapp || this.parent.send_sms) ? this.parent.phone : true
        }).test('user_has_email', t('user-must-have-an-email'), function (value) {
            return (this.parent.send_email) ? this.parent.email : true
        }),
        available_on_app: yup.boolean().required(t('thisFieldIsMandatory')),
        item_id: yup.number().required(t('thisFieldIsMandatory')),
        item_type: yup.string().required(t('thisFieldIsMandatory')),
        send_push: yup.boolean(),
        send_email: yup.boolean(),
        send_sms: yup.boolean().test('send_sms', t('select-at-least-1'), function(value){
            return this.parent.send_email || this.parent.send_whatsapp || this.parent.send_push || value
        }),
        send_whatsapp: yup.boolean(),
        subject: yup.string().when('send_email', {
            is: true,
            then: yup.string().required(t('thisFieldIsMandatory'))
        }).test('no-emojis', t('no-emojis-are-allowed'), isStringWithoutEmoji),
        message_content: yup.string().required(t('thisFieldIsMandatory'))
            .test('no-emojis', t('no-emojis-are-allowed'), isStringWithoutEmoji)
            .test('link-required', t('link-is-required'), function (value) {
                return this.parent.message_content?.includes(`[${t('link-to-membership')}]`)
            }),
        })
}

const getRequiredFieldsSettingsValidationSchema = () => {
    const flowName = store.getState().stepper?.name?.toLowerCase()
    if (!boxSettingsProps[flowName]) return null;
    return yup.object().shape({
        properties: yup.object().shape({
            ...boxSettingsProps[flowName].fields.reduce((accField, currField) => ({
                ...accField,
                [currField]: yup.object().shape({
                    ...boxSettingsProps[flowName].flows.reduce((accFlow, currFlow) => ({
                        ...accFlow,
                        [`${currFlow}Required`]: yup.boolean()
                    }), {})
                })
            }), {})
        })
    })
}

const getRepeatInitialValues = (target, eventDateTime, selectedTime, selectedDay, flowName) => {
    if(target) { //for group session only, for now
        return {
            date: target.date,
            time: moment(target.time, 'HH:mm').format(dbTimeFormat),
            end_time: moment(target.end_time, 'HH:mm').format(dbTimeFormat),
            repeatConfig: null
        }
    }
    return {
        date: eventDateTime?.date ?? moment().format(dbDateFormat),
        time: selectedTime,
        end_time: eventDateTime?.end ?? moment(selectedTime, dbTimeFormat).add(1, 'h').format(dbTimeFormat),
        repeatConfig: {
            repeat: ![flowOptions.APPOINTMENT_BASIC, flowOptions.BLOCKED_TIME].includes(flowName),
            type: flowName !== flowOptions.APPOINTMENT_BASIC ? 'forever' : 'times',
            until: null,
            times: 1,
            days: [selectedDay],
            seriesName: null
        }
    }
}

const getAvailabilityConfigInitialValues = (target, eventDateTime, selectedDay) => {
    const isEdit = store.getState().stepper.editType
    let vals = {
        date: isEdit ? target?.date : (eventDateTime?.date ?? moment().format(dbDateFormat)),
        time: null,
        end_time: null,
    }
    if(!isEdit) {
        vals.repeatConfig = {
            repeat: false,
            type: null,
            until: null,
            times: null,
            days: [selectedDay],
            seriesName: null
        }
    }
    return vals
}

const getSettingsInitialValues = (target = null) => {
    const isEdit = store.getState().stepper.editType
    const flowName = store.getState().stepper.name
    if(isEdit && target) {
        let params = {
            transparent: !!target.transparent,
            disable_cancellation_time: target.disable_cancellation_time ?? 0,
            enable_late_cancellation: !!target.enable_late_cancellation,
            enable_registration_time: target.enable_registration_time ?? 0,
            block_registration_time: target.block_registration_time ?? 0,
            cancel_limit: target.cancel_limit,
            min_users: target.min_users,
            live_link: target.live_link,
            register_group_member: !!target.series?.register_group_member,
            allow_mid_booking: !!target.series?.allow_mid_booking
        }
        if(isEdit === SERIES_FUTURE_SESSIONS_EDIT_TYPE && target.series) {
            params.series_name = target.series.series_name
            if (flowName === flowOptions.GROUP_CLASS_FLOW) {
                params.paymentOptions = getPaymentOptionsValue(target)
            }
        }
        return params
    } else {
        return {
            transparent: [flowOptions.WORKSHOP_FLOW, flowOptions.HUGIM_FLOW].includes(flowName),
            disable_cancellation_time: 0,
            enable_late_cancellation: false,
            enable_registration_time: 0,
            block_registration_time: 0,
            cancel_limit: 0,
            min_users: 0,
            live_link: null,
            register_group_member: false,
            allow_mid_booking: false
        }
    }
}

const getPaymentOptionsValue = (target) => {
    const trialMembership = target.series?.membership_types_all?.filter(mt => mt.show_in_app).find(mt => mt.type === MembershipTypes.TRIAL_CLASS);
    return trialMembership ? [{...trialMembership, type: seriesPaymentDBTranslate[trialMembership.type],
        taxes: trialMembership.membership_types_taxes.map(membershipTax => membershipTax.taxes_id) ?? [],
        paid: trialMembership.price > 0}] : null
}

const getEditMultiDaysInitialValues = (eventDateTime, target) => {
    const flowName = store.getState().stepper.name
    const editType = store.getState().stepper.editType
    if(!editType) return {}

    switch (flowName) {
        case flowOptions.WORKSHOP_FLOW:
            return {
                date: target.date,
                days: target.series.series_events.reduce((acc,e) => [...acc, {
                    start_time: e.start,
                    end_time: e.end,
                    day_of_week: e.day_of_week,
                    id: e.id
                }],[])
            }
        case flowOptions.HUGIM_FLOW:
            if(editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE) {
                return {
                    isUnlimited: target.series.end_date === null,
                    date: target.date,
                    start_date: moment(target.series.start_date, dbDateFormat).format(dbDateFormat),
                    end_date: target.series.end_date ? moment(target.series.end_date, dbDateFormat).format(dbDateFormat) : null,
                    days: target.series.series_events.reduce((acc,e) => [...acc, {
                        start_time: e.start,
                        end_time: e.end,
                        day_of_week: e.day_of_week,
                        id: e.id
                    }],[])
                }
            } else {
                return {
                    date: target.date,
                    start_time: moment(target.time, dbTimeFormat).format(dbTimeFormat),
                    end_time: moment(target.end_time, dbTimeFormat).format(dbTimeFormat)
                }
            }
        case flowOptions.AVAILABILITY:
        case flowOptions.SPACE_AVAILABILITY:
            return {
                date: eventDateTime?.date,
                location_fk: target.location_fk,
                coach_ub_id: target.coach_ub_id,
                spaces_id: target.spaces_id,
                repeat: target.repeat,
                slot_length: target.slot_length,
                break_length: target.break_length,
                block_registration_time: target.block_registration_time ?? 0,
                start_time: target.start_time,
                end_time: target.end_time,
                day_of_week: moment(target.date, dbDateFormat).day(),
                limitations: target.availability_limitations.map(item => item.category_type_id),
            }
        default:
            return {}
    }
}

const getMemberDetailsInitialValues = (target) => {
    const type = store.getState().stepper.name
    const defaultLocation = store.getState().user.user.locations_box_fk
    return {
        birthday: target?.birthday ||  null,
        gender: target?.gender || '',
        email: target?.email || '',
        phone: target?.phone || '',
        first_name: target?.firstName || '',
        last_name: target?.lastName || '',
        locations_box_fk: target?.locationBoxFk || defaultLocation || '',
        personal_id: target?.personal_id || '',
        birthday: target?.birthday || null,
        allow_sms: target?.allow_sms || type === flowOptions.LEAD ? 'yes' : 'unknown',
        allow_mailing_list: target?.allow_mailing_list || type === flowOptions.LEAD ? 'yes' : 'unknown',
        status_fk: (type === flowOptions.LEAD && target?.status) || null,
        source_fk: (type === flowOptions.LEAD && target?.source) || null,
        users_boxes_owner_id: target?.users_boxes_owner_id || null,
        city: target?.city || '',
        address: target?.address || '',
        updatedUser: target?.id && {
            id: target?.id || null,
            convertMemberToLead: target?.id ? target?.action !== 'update' : false
        }
    }
}

const getEmailAfterPurchaseInitialValues = (target) => {
    return {
        membership_types: target?.auto_actions_set_membership_types?.map?.(mt => ({...mt, value: mt.membership_types_id, id: mt.membership_types_id,})) ?? [],
        users_boxes: JSON.parse(target?.auto_actions_set_triggers?.properties || null)?.notify_list?.find?.(l => l.locations_box_id === target?.locations_box_fk)?.users_boxes?.map?.(id => ({id, value: id})) ?? [],
        locations_box_id: target?.locations_box_fk
    }
}

const getPenaltySettingsInitialValues = (target) => {
    return {
        name: target?.name || '',
        start_date: target?.start_date || moment().format(dbDateFormat),
        trigger_occurrence_count: target?.trigger_occurrence_count || 1,
        trigger_period_count: target?.trigger_period_count || 1,
        late_cancellation: target?.late_cancellation ?? true,
        check_in: target?.check_in ?? true,
        type: target?.type || 'block_app_cancel',
        reduction: target?.type_value || 1,
        block_app_cancel: target?.type_value || 1,
        block_app_no_cancel: target?.type_value || 1,
        time_after_class: target?.time_after_class ||  1,
        ...(target ? getPenaltyExtraPropertiesInitialValues(target) : {}),
        ...(target ? {id: target.id, message_template_id: target.message_template_id} : {})
    }
}

const getPenaltyExtraPropertiesInitialValues = (target) => {
    let message = target?.message_template?.text || '';
    const tags = getPenaltiesTags(target?.type)
    tags.forEach(tag => {
        message = message.replaceAll(`{{#${tag.id}#}}`, `[${tag.label}]`)
    });
    return {
        membership_types: target?.membership_types?.map(mt => ({id: mt.membership_types_id, value: mt.membership_types_id})) || [],
        send_push: target?.send_push ?? true,
        send_email: target?.send_email ?? false,
        send_sms: target?.send_sms ?? false,
        free_space: target?.free_space ||  1,
        allow_free_space: !!(target?.free_space ||  0),
        message_content: message
    }
}

const getTaskDetailsInitialValues = (target) => {
    const user = store.getState().user.user
    return {
        id: target?.id,
        reminder_time: target?.reminder_time || null,
        user_fk: target?.user_fk || null,
        task_type_fk: target?.task_type_fk || null,
        assigned_to: target ? target?.assigned_to : user.user_fk,
        description: target?.description || '',
        locations_box_fk: target?.locations_box_fk || user.locations_box_fk,
        done: target?.done || 0,
        is_personal: target?.targeted === 'personal' ? 1 : 0,
        targeted: target?.is_personal ? targetAudienceTasks.PERSONAL : (target ? target.targeted : targetAudienceTasks.SPECIFIC_USER),
        task_owner_user_fk: target?.task_owner_user_fk || null,
        check_in: target?.check_in || false,
        access_control: target?.access_control || false,
        reports_users: target?.targeted === 'reports' && Object.keys(target.selectedUsers),
        is_done_button_click: false
    };
}

const getSendConvertLinkInitialValues = (target) => {
    return {
        user_fk: target?.user?.user_fk || null,
        phone: target?.user?.phone || null,
        email: target?.user?.email || null,
        full_name: target?.user?.full_name || null,
        send_sms: true,
        send_email: false,
        send_whatsapp: false,
        subject: '',
        message_content: ''
    };
}

const getSeriesPaymentOptionsInitialValues = (target) => {
    const editType = store.getState().stepper.editType
    if(editType) {
        return {
            paymentOptions: target?.series?.membership_types_all.map(mt => ({...mt, type: seriesPaymentDBTranslate[mt.type], taxes: mt.membership_types_taxes.map(membershipTax => membershipTax.taxes_id) ?? []}))
        }
    } else {
        return {paymentOptions: []}
    }
}

const getPersonalDetailsInitialValues = (target) => {
    const editType = store.getState().stepper?.editType
    return {
        first_name: '',
        last_name: '',
        email: null,
        emailValidated: null,
        phone: null,
        birthday: null,
        gender: null,
        personal_id: null,
        personalIdValidated: null,
        rfid: null,
        invoice_name: null,
        allow_mailing_list: 'yes',
        allow_sms: 'yes',
        locations_box_fk: target?.series?.locations_box_fk,
        customFields: customFieldInit(target, editType)
    }
}

const getRequiredFieldsSettingsInitialValues = () => {
    const flowName = store.getState().stepper?.name?.toLowerCase()
    if (!flowName) return {}
    const boxSettings = store.getState().box.box.settings?.[flowName]
    if (boxSettings) return {properties: boxSettings, prop_name: flowName};
    let initialProps = {};
    if (!boxSettingsProps[flowName]) return null;
    boxSettingsProps[flowName].fields.forEach(prop => {
        initialProps[prop] = getSettingsObjectByType(prop, boxSettingsProps[flowName].flows)
    });
    return {properties: initialProps, prop_name: flowName};
}

const getCreateTaskTypeInitialValues = (target) => {
    return {
        task_type_color: target?.task_type_color || "cat-color-1",
        type: target?.type || '',
        check_in: target?.check_in || false,
        access_control: target?.access_control || false,
        id: target?.task_type_id

    };
}

const getSendMembershipLinkInitialValues = (target) => {
    return {
        user_fk: target?.user?.user_fk || null,
        phone: target?.user?.phone || null,
        email: target?.user?.email || null,
        full_name: target?.user?.full_name || null,
        available_on_app: true,
        item_id: null,
        send_push: true,
        send_sms: false,
        send_email: false,
        send_whatsapp: false,
        subject: '',
        message_content: ''
    };

}

const getEditMultiDaysValidationSchema = () => {
    const flowName = store.getState().stepper.name
    const editType = store.getState().stepper.editType

    switch (flowName) {
        case flowOptions.WORKSHOP_FLOW:
            return getWorkshopSeriesDaysEditValidationSchema().shape({
                date: yup.string().required(t('thisFieldIsMandatory')).test('date-format', 'invalid date', function(value){
                    return moment(value, dbDateFormat).format(dbDateFormat) === value
                })
            })
        case flowOptions.AVAILABILITY:
            return getEditAvailabilityBasicValidationSchema().shape({
                coach_ub_id: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
            })
        case flowOptions.SPACE_AVAILABILITY:
            return getEditAvailabilityBasicValidationSchema().shape({
                spaces_id: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
            })
        case flowOptions.HUGIM_FLOW:
            if(editType === SERIES_FUTURE_SESSIONS_EDIT_TYPE) {
                return getWorkshopSeriesDaysEditValidationSchema().shape({
                    isUnlimited: yup.boolean(),
                    date: yup.string().required(t('thisFieldIsMandatory')).test('date-format', 'invalid date', function(value){
                        return moment(value, dbDateFormat).format(dbDateFormat) === value
                    }),
                    start_date: yup.string().required(t('thisFieldIsMandatory')).test('date-format', 'invalid date', function(value){
                        return moment(value, dbDateFormat).format(dbDateFormat) === value
                    }),
                    end_date: yup.string().when("isUnlimited", {
                        is: true,
                        then: yup.string().nullable(true).test('is-null', 'end_date must be null', value => value === null), //must be null when isUnlimited=true
                        otherwise: yup.string().required(t('thisFieldIsMandatory')).when(['start_date'], (start_date, schema) => {
                            const start = moment(start_date, dbDateFormat)
                            return schema.test({
                                test: end => end && moment(end, dbDateFormat).isAfter(start),
                                message: t('shiftTimeError')
                            })
                        }).test('date-format', 'invalid date', function(value){
                            return value && (moment(value, dbDateFormat).format(dbDateFormat) === value)
                        }),
                    })
                })
            } else {
                return yup.object().shape({
                    date: yup.string().required(t('thisFieldIsMandatory')).test('date-format', 'invalid date', function(value){
                        return moment(value, dbDateFormat).format(dbDateFormat) === value
                    }),
                    start_time: yup.string().required(t('thisFieldIsMandatory')).test('time-format', 'invalid time', function (value) {
                        return moment(value, dbTimeFormat).format(dbTimeFormat) === value
                    }),
                    end_time: yup.string().required(t('thisFieldIsMandatory')).when(['start_time'], (start_time, schema) => {
                        const start = moment(start_time, dbTimeFormat)
                        return schema.test({
                            test: end => moment(end, dbTimeFormat).isAfter(start),
                            message: t('shiftTimeError')
                        })
                    }).test('time-format', 'invalid time', function (value) {
                        console.log(' end time',value, moment(value, dbTimeFormat).format(dbTimeFormat))
                        return moment(value, dbTimeFormat).format(dbTimeFormat) === value
                    }),
                })
            }
        default:
            return yup.object().shape({})
    }
}

const getWorkshopSeriesDaysEditValidationSchema = () => {
    return yup.object().shape({
        days: yup.array().of(yup.object().shape({
            start_time: yup.string().required(t('thisFieldIsMandatory')).test('time-format', 'invalid time', function (value) {
                return moment(value, dbTimeFormat).format(dbTimeFormat) === value
            }),
            end_time: yup.string().required(t('thisFieldIsMandatory')).when(['start_time'], (start_time, schema) => {
                const start = moment(start_time, dbTimeFormat)
                return schema.test({
                    test: end => moment(end, dbTimeFormat).isAfter(start),
                    message: t('shiftTimeError')
                })
            }).test('time-format', 'invalid time', function (value) {
                return moment(value, dbTimeFormat).format(dbTimeFormat) === value
            }),
            day_of_week: yup.number().min(0).max(6).required(t('thisFieldIsMandatory')),
        }))
    })
}

const getSpaceConfigValidationSchema = () => {
    return yup.object().shape({
        locations_box_id: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        name: yup.string().required(t('thisFieldIsMandatory')).min(2, t('nameErrorMsg')).max(20, t('nameErrorMsg')),
        availabilityInfo: getAvailabilityBasicValidationSchema().nullable()
    })
}

const getAvailabilityBasicValidationSchema = () => {
    return yup.object().shape({
        date: yup.string().required(t('thisFieldIsMandatory')).test('date-format', 'invalid date', function (value) {
            return moment(value, dbDateFormat).format(dbDateFormat) === value
        }),
        repeat: yup.boolean().required(),
        slot_length: yup.number().min(1,`${t('largerThanErrorMsg')}1`).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        break_length: yup.number().min(0,`${t('largerThanErrorMsg')}0`).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        block_registration_time: yup.number().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        multiDayConfig: yup.array().of(yup.object().shape({
            day: yup.number().min(0).max(6).required(t('thisFieldIsMandatory')),
            setting: yup.array().of(yup.object().shape({
                start_time: yup.string().required(t('thisFieldIsMandatory')).test('time-format', 'invalid time', function (value) {
                    return moment(value, dbTimeFormat).format(dbTimeFormat) === value
                }),
                end_time: yup.string().required(t('thisFieldIsMandatory')).when(['start_time'], (start_time, schema) => {
                    const start = moment(start_time, dbTimeFormat)
                    return schema.test({
                        test: end => moment(end, dbTimeFormat).isAfter(start),
                        message: t('shiftTimeError')
                    })
                }).test('time-format', 'invalid time', function (value) {
                    return moment(value, dbTimeFormat).format(dbTimeFormat) === value
                }),
                limitations: yup.array().ensure()
            }))
        })).min(1, t('one-day-minimum')),
    })
}

const getEditAvailabilityBasicValidationSchema = () => {
    return yup.object().shape({
        date: yup.string().required(t('thisFieldIsMandatory')).test('date-format', 'invalid date', function(value){
            return moment(value, dbDateFormat).format(dbDateFormat) === value
        }),
        location_fk: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        slot_length: yup.number().positive().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        break_length: yup.number().min(0,`${t('largerThanErrorMsg')}0`).required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        block_registration_time: yup.number().required(t('thisFieldIsMandatory')).typeError(t('thisFieldIsMandatory')),
        start_time: yup.string().required(t('thisFieldIsMandatory')).test('time-format', 'invalid time', function (value) {
            return moment(value, dbTimeFormat).format(dbTimeFormat) === value
        }),
        end_time: yup.string().required(t('thisFieldIsMandatory')).when(['start_time'], (start_time, schema) => {
            const start = moment(start_time, dbTimeFormat)
            return schema.test({
                test: end => moment(end, dbTimeFormat).isAfter(start),
                message: t('shiftTimeError')
            })
        }).test('time-format', 'invalid time', function (value) {
            return moment(value, dbTimeFormat).format(dbTimeFormat) === value
        }),
        day_of_week: yup.number().min(0).max(6).required(t('thisFieldIsMandatory')),
        limitations: yup.array().ensure()
    })
}

const getPenaltiesSettingsValidationSchema = () => {
    const target = store.getState().stepper.props.target;
    const all = store.getState().stepper.props.all?.filter(p => p.id !== target?.id)?.map(p => p.name);
    return yup.object().shape({
        name: yup.string().required(t('thisFieldIsMandatory')).test('name-unique', t('name-must-be-unique'), function (val) {
            return !all.includes(val);
        }),
        start_date: yup.string().required().test('start_date', t('start-date-must-be-a-future-date'), function (val) {
            if (target && target.start_date < moment().format(dbDateFormat)) {
                return true;
            }
            return val >= moment().format(dbDateFormat)
        }),
        trigger_occurrence_count: yup.number().min(1).required(t('thisFieldIsMandatory')),
        trigger_period_count: yup.number().min(1).required(t('thisFieldIsMandatory')),
        late_cancellation: yup.boolean().test('late_cancellation', t('select-at-least-1'), function(value){
            return this.parent.check_in || value
        }),
        check_in: yup.boolean().test('check_in', t('select-at-least-1'), function(value){
            return this.parent.late_cancellation || value
        }),
        type: yup.string().oneOf(['reduction', 'block_app_cancel', 'block_app_no_cancel']).required(),
        reduction: yup.number().when('type', {
            is: value => value === 'reduction',
            then: yup.number().min(1).required(t('thisFieldIsMandatory'))
        }),
        block_app_cancel: yup.number().when('type', {
            is: value => value === 'block_app_cancel',
            then: yup.number().min(1).required(t('thisFieldIsMandatory'))
        }),
        block_app_no_cancel: yup.number().when('type', {
            is: value => value === 'block_app_no_cancel',
            then: yup.number().min(1).required(t('thisFieldIsMandatory'))
        }),
        time_after_class: yup.number().oneOf([1,2,3,4]).required()
    }).concat(target ? getExtraPenaltiesPropertiesValidationSchema() : yup.object().shape({}));
}

const getExtraPenaltiesPropertiesValidationSchema = () => {
    return yup.object().shape({
        membership_types: yup.array(yup.object().shape({
            id: yup.number().required()
        })).min(1, t('select-at-least-1-plan-or-session-pack')),
        available_in_app: yup.boolean(),
        item_id: null,
        send_push: yup.boolean(),
        send_email: yup.boolean(),
        send_sms: yup.boolean(),
        message_content: yup.string().required(t('thisFieldIsMandatory')),
        free_space: yup.number(t('onlyWhole')).integer(t('onlyWhole')).min(0, t('onlyPositive')).typeError(t('onlyWhole'))
    })
}

const getSendConvertLinkValidationSchema = () => {
    return yup.object().shape({
        user_fk: yup.number().required(t('thisFieldIsMandatory')).test('user_has_phone_email', t('user-must-have-a-phone-number-and-email'), function (value) {
            return (this.parent.send_whatsapp || this.parent.send_sms) && this.parent.send_email ? this.parent.phone && this.parent.email : true
        }).test('user_has_phone', t('user-must-have-a-phone-number'), function (value) {
            return (this.parent.send_whatsapp || this.parent.send_sms) ? this.parent.phone : true
        }).test('user_has_email', t('user-must-have-an-email'), function (value) {
            return (this.parent.send_email) ? this.parent.email : true
        }),
        send_email: yup.boolean(),
        send_sms: yup.boolean().test('send_sms', t('select-at-least-1'), function(value){
            return this.parent.send_email || this.parent.send_whatsapp || value
        }),
        send_whatsapp: yup.boolean(),
        subject: yup.string().when('send_email', {
            is: true,
            then: yup.string().required(t('thisFieldIsMandatory'))
        }).test('no-emojis', t('no-emojis-are-allowed'), isStringWithoutEmoji),
        message_content: yup.string().required(t('thisFieldIsMandatory'))
        .test('no-emojis', t('no-emojis-are-allowed'), isStringWithoutEmoji)
        .test('link-required', t('link-is-required'), function (value) {
            return this.parent.message_content?.includes(`[${t('link-to-convert')}]`)
        }),
    })
}

const getBasicWorkshopValidationSchema = () => {
    return yup.object().shape({
        start_date: yup.string().required(t('thisFieldIsMandatory')).test('date-format', 'invalid date', function(value){
            return moment(value, dbDateFormat).format(dbDateFormat) === value
        }),
        multiDayConfig: yup.object().shape({
            days: yup.array().of(yup.object().shape({
                day: yup.number().min(0).max(6),
                start_time: yup.string().required(t('thisFieldIsMandatory')).test('time-format', 'invalid time', function (value) {
                    return moment(value, dbTimeFormat).format(dbTimeFormat) === value
                }),
                end_time: yup.string().required(t('thisFieldIsMandatory')).when(['start_time'], (start_time, schema) => {
                    const start = moment(start_time, dbTimeFormat)
                    return schema.test({
                        test: end => moment(end, dbTimeFormat).isAfter(start),
                        message: t('shiftTimeError')
                    })
                }).test('time-format', 'invalid time', function (value) {
                    return moment(value, dbTimeFormat).format(dbTimeFormat) === value
                }),
            })).min(1, t('one-day-minimum')),
            seriesName: yup.string().nullable()
        })
    })
}